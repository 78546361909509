import React, { useState, useEffect } from "react";
import axios from "axios";
import PreviewMovie from "./PreviewMovie";
import { useNavigate } from "react-router-dom";

const SearchMovieForEdit = ({ onMovieSelected }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [movies, setMovies] = useState([]);
    const [selectionMessage, setSelectionMessage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const nextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    useEffect(() => {
        const fetchMovies = async () => {
            if (searchTerm) {
                const response = await axios.get(`https://api.themoviedb.org/3/search/movie?api_key=e16900047627f56531a2d3d8d4df9e92&query=${searchTerm}`);
                setMovies(response.data.results);
            }
        };

        fetchMovies();
    }, [searchTerm]);

    return (
        <div className="searchMovie">
            <div className="input-container">
                <input className="input" placeholder="Entrez un mot clé" onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            {selectionMessage && (
                <div className="message-container">
                    <h4 style={{color: '#D90093', fontWeight: 'Bolder'}}>{selectionMessage}</h4>
                </div>
            )}
            {searchTerm ? (
                <ul className="searchMovie-list">
                    {movies.map((m) => (
                        <PreviewMovie
                            movie={m}
                            key={m.id}
                            onClickMovie={(movie) => {
                                onMovieSelected(movie);
                                setMovies([]);  // efface les résultats
                                setSelectionMessage('Votre preview coup de coeur est sélectionnée');
                                // setTimeout(() => {
                                //     setSelectionMessage(null);
                                // }, 3000);
                            }}
                        />
                    ))}
                </ul>
            ) : (
                <div className="placeholder-container">
                    <h4>Rien à afficher, veuillez entrer votre mot de recherche</h4>
                </div>
            )}
            <div className="pagination">
                <button onClick={prevPage}>Page précédente</button>
                <span className="currentPage">Page {currentPage}</span>
                <button onClick={nextPage}>Page suivante</button>
            </div>
        </div>
    );
    
};

export default SearchMovieForEdit;
