import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebasedeconfig';
import { collection, doc, getDoc, setDoc, deleteDoc, userRef } from "firebase/firestore";
import { db } from './firebasedeconfig';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [banner, setBanner] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);

  const fetchUserData = async (user) => {
    if (!user || !user.uid) return;
    const userRef = doc(db, "users", user.userId); // user.userId
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const { username: userUsername, email: userEmail, banner: userBanner, avatar: userAvatar } = userDoc.data();
      setUsername(userUsername);
      setEmail(userEmail);
      setBanner(userBanner);
      setAvatar(userAvatar);
      setUserIsLoggedIn(true);
      setIsLoggedIn(true);
    }
  };
  

  const updateContext = (newUsername, newEmail, newAvatar, newBanner, newIsLoggedIn, newUserIsLoggedIn) => {
    if (newUsername !== username) {
      setUsername(newUsername);
    }
    if (newEmail !== email) {
      setEmail(newEmail);
    }
    if (newAvatar !== avatar) {
      setAvatar(newAvatar);
    }
    if (newBanner !== banner) {
      setBanner(newBanner);
    }
    if (newIsLoggedIn !== isLoggedIn) {
      setIsLoggedIn(newIsLoggedIn);
    }
    if (newUserIsLoggedIn !== userIsLoggedIn) {
      setUserIsLoggedIn(newUserIsLoggedIn);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log("Test FicheFilm FavoriteMovie06. Is Logged In:", isLoggedIn);
      if (user) {
        // Vérifiez si userId est null avant de continuer
        if (user.uid !== null) {
          setUserId(user.uid);
          setIsLoggedIn(true); 
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const { banner: userBanner, avatar: userAvatar } = userDoc.data();
            if (banner !== userBanner || avatar !== userAvatar) {
              setBanner(userBanner);
              setAvatar(userAvatar);
            }
          }
        }
      } else {
        setUserId(null);
        setUsername(null);
        setEmail(null);
        setAvatar(null);
        setBanner(null);
        setIsLoggedIn(false);
        setUserIsLoggedIn(false);
      }
      console.log("Test FicheFilm FavoriteMovie07. Valeur de isLoggedIn:", isLoggedIn);
    });
    return () => unsubscribe();
  }, [avatar, banner, isLoggedIn, userIsLoggedIn, userId, username, email, setUserId, setAvatar, setBanner, setIsLoggedIn, setUsername, setEmail, setUserIsLoggedIn ]);
   // Aucune dépendance ici pour éviter les boucles infinies
  
  const resetContext = () => {
    setIsLoggedIn(false);
    setUserIsLoggedIn(false);
    setUsername(null);
    setEmail(null);
    setAvatar(null);
    setBanner(null);
  };

  useEffect(() => {
    console.log("Debugging UserContext:", username, email, avatar);
  }, [username, email, avatar]);

  // return userId && (
  return (
    <UserContext.Provider value={{
      username, setUsername, email, setEmail,
      avatar, setAvatar, banner, setBanner,
      isLoggedIn, setIsLoggedIn, updateContext, resetContext, fetchUserData,
      userIsLoggedIn, setUserIsLoggedIn,       
      userId, setUserId
    }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;






