import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { UserContext } from '../UserContext';
import { db, auth } from "../firebasedeconfig";
import { AnimeGirl } from "./AnimeGirl.js";
import { Helmet } from "react-helmet"; // Import de Helmet

const CategoriesMovies = () => {
  const { selectedCategory } = useParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useNavigate();

  const { isLoggedIn } = useContext(UserContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [showCategoryConfirmation, setShowCategoryConfirmation] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const URL_GENRES =
    "https://api.themoviedb.org/3/genre/movie/list?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR";

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const fetchMovieDetails = async () => {
    try {
      const response = await axios.get(URL_GENRES);
      const category = response.data.genres.find(
        (genre) => genre.id === Number(selectedCategory)
      );
      if (category) {
        setSelectedCategoryName(category.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchCategoryMovies = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/discover/movie?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR&sort_by=popularity.desc&include_adult=false&include_video=false&page=${currentPage}&with_genres=${selectedCategory}`
        );
        setData(response.data.results);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryMovies();
  }, [selectedCategory, currentPage]);

  useEffect(() => {
    fetchMovieDetails();
  }, [selectedCategory]);

  const addFavoriteCategory = async () => {
    try {
      if (!isLoggedIn || !selectedCategoryName) return;

      const userId = auth.currentUser.uid;
      const userRef = doc(db, "users", userId);
      const userSnap = await getDoc(userRef);
      const userData = userSnap.data();

      if (!userSnap.exists()) {
        console.error("No such user!");
        return;
      }

      const newFavoriteCategories = [...(userData.favoriteCategories || []), { id: selectedCategory, name: selectedCategoryName }];

      await updateDoc(userRef, {
        favoriteCategories: newFavoriteCategories
      });

      setShowCategoryConfirmation(true);
      setTimeout(() => {
        setShowCategoryConfirmation(false);
      }, 3000);
    } catch (error) {
      console.error("Error adding favorite category:", error);
    }
  };

  return (
    <div className="categoriesMovies">
      {/* Helmet pour les balises SEO dynamiques */}
      <Helmet>
        <title>{selectedCategoryName ? `Films - ${selectedCategoryName} | MovieFrenzy` : "Catégories de Films | MovieFrenzy"}</title>
        <link rel="canonical" href={`https://moviefrenzy.ca/categories/${selectedCategory}`} />
        <meta
          name="description"
          content={
            selectedCategoryName
              ? `Découvrez une vaste sélection de films dans la catégorie ${selectedCategoryName} sur MovieFrenzy. Profitez de recommandations adaptées à vos goûts cinématographiques.`
              : "Explorez nos catégories de films sur MovieFrenzy et trouvez votre prochain coup de cœur cinématographique."
          }
        />
      </Helmet>

      {selectedCategory && (
        <div className="containerCatTitle">
          <h1 className="titleCategory">{selectedCategoryName}</h1>
          {selectedCategoryName === "Animation" && <AnimeGirl isLoggedIn={isLoggedIn} />}
          {isLoggedIn && (
            <div
              className="favoriteCategoryButton"
              onClick={addFavoriteCategory}
              style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/cinemaFavoriteButtonWhite.png)` }}
            ></div>
          )}
        </div>
      )}

      {showCategoryConfirmation && (
        <h2
          className="CategoriesAjoutTitre"
          style={{ color: "#00FFFF", marginLeft: "5%", zIndex: "1000" }}
        >
          Catégorie ajoutée à vos favoris !
        </h2>
      )}

      <div className="categoriesMovies-content">
        {selectedCategory ? (
          <ul className="categoriesMovies-list">
            {data.map((m) => (
              <Movie
                key={m.id}
                movie={m}
                onClickMovie={() => history("/FicheFilm", { id: m.id })}
              />
            ))}
          </ul>
        ) : (
          <div className="placeholder-container">
            <h4>Rien à afficher, veuillez sélectionner une catégorie</h4>
          </div>
        )}
      </div>

      <div className="pagination">
        <button onClick={prevPage}>Page précédente</button>
        <span>Page {currentPage}</span>
        <button onClick={nextPage}>Page suivante</button>
      </div>
    </div>
  );
};

export default CategoriesMovies;

