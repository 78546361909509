
import React, { useEffect, useRef } from 'react';

const CircularNicknameSvg = ({ username }) => {
  const textElementRef = useRef(null);

  useEffect(() => {
    const adjustFontSize = () => {
      
      if (!username) return;  // Ajoutez cette ligne pour la vérification

      // Obtenez l'élément texte du SVG
      const textElement = textElementRef.current;
  
      // Calculez le nombre de caractères
      const charCount = username.length;
  
      // Trouvez combien de tranches de 10 caractères supplémentaires sont là
      const extraTens = Math.floor((charCount - 10) / 10);
  
      // Définissez la taille de la police initiale
      let fontSize = 4; // 4em est la taille initiale
  
      // Réduisez la taille de la police de 20 % pour chaque tranche de 10 caractères supplémentaires
      for (let i = 0; i < extraTens; i++) {
        fontSize *= 0.65;
      }
  
      // Appliquez la nouvelle taille de police à l'élément texte
      textElement.style.fontSize = `${fontSize}em`;
    }

    adjustFontSize();
  }, [username]);

  return (
    <div className="circularNickNameSvg" style={username ? { backgroundImage: 'none' } : {}}>
      <svg width="500" height="500">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#000" stopOpacity="1" />
            <stop offset="50%" stopColor="#00FFFF" stopOpacity="1" />
            <stop offset="100%" stopColor="#D90093" stopOpacity="1" />
          </linearGradient>
        </defs>
        <path id="curve" d="M 10,250 A 240,240 0 0,0 490,250" fill="none" />
        <text ref={textElementRef} width="500" fill="url(#gradient)" >
          <textPath href="#curve" startOffset="50%" textAnchor="middle">
            {username ? username : ''}
          </textPath>
        </text>
      </svg>
    </div>
  );
}

export default CircularNicknameSvg;

