import React from "react";
import NavigationBar from "../components/Navigation";
import Footer from "../components/Footer";
import "../styles/components/Forum.scss";

const Forum = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "overlay",
          backgroundColor: "#200211",
          opacity: "0.9",
          overflowX: "hidden",
          overflowY: "auto",
          minHeight: "100vh", // Pour s'assurer que le fond couvre toute la page
        }}
      >
        <NavigationBar />
        <div className="forum-container">
          <h1>Forum</h1>
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              placeholder="Nouveau sujet"
              style={{ width: "100%", maxWidth: "400px" }} // Réduction de la largeur
            />
            <button type="submit">Créer</button>
          </form>
          <ul>
            <li>
              <h2>Sujet Exemple</h2>
              <ul>
                <li className="response">Réponse 1</li>
                <li className="response">Réponse 2</li>
              </ul>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Forum;
