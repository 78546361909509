// tsparticles.js
import React, { useCallback } from "react";
import { Particles } from 'react-tsparticles';
import { loadFull } from "tsparticles";

const MyParticlesComponent = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    console.log(container);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fpsLimit: 60,
        particles: {
          color: {
            value: ["#FF0000", "#FFFFFF", "#0000FF"], // Rouge, blanc, bleu (drapeau USA)
          },
          move: {
            direction: "bottom",
            enable: true,
            speed: 1,
          },
          number: {
            value: 50,
          },
          shape: {
            type: "star",
          },
          size: {
            value: 2.5,
            random: true,
          },
          opacity: {
            value: 1,
          },
          line_linked: {
            enable: false, // Assurez-vous que les particules ne sont pas connectées
          },
        },
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "repulse", // Optionnel : effet interactif au survol
            },
            onClick: {
              enable: true,
              mode: "push", // Optionnel : ajoute des particules au clic
            },
          },
          modes: {
            repulse: {
              distance: 100,
            },
          },
        },
      }}
    />
  );
};

export default MyParticlesComponent;
