import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};

// Initialisez Firebase
const app = initializeApp(firebaseConfig);

// Authentification Firebase
const auth = getAuth(app);

// Stockage Firebase
const storage = getStorage(app);

// Base de données Firestore
const db = getFirestore(app);

// Activer la persistance IndexedDB pour Firestore
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Probablement plusieurs onglets ouverts à la fois.
      console.error("La persistance de Firestore n'a pas pu être activée car il y a des onglets multiples ouverts sur la même instance de Firestore.", err);
    } else if (err.code == 'unimplemented') {
      // Le navigateur ne prend pas en charge la persistance IndexedDB.
      console.error("Le navigateur ne prend pas en charge les fonctionnalités requises pour la persistance de Firestore.", err);
    }
  });

// Exposition des instances pour utilisation dans d'autres fichiers
export { auth, db, storage };




// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";

// // Configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const db = getFirestore(app);
// console.log("Firestore instance: ", db);
// const storage = getStorage(app);

// console.log("API Key: ", process.env.REACT_APP_FIREBASE_API_KEY);

// export { auth, db, storage };