import React from "react";
import NavigationBar from "../components/Navigation";
import Slider from "../components/Slider";
import Footer from "../components/Footer";
import SectionsAccueil from "../components/sectionsAccueil";
import ActeurContext from "../contexts/ActeurContext";
import { useNavigate } from "react-router-dom";
import BackToTopButton from '../components/BackToTopButton';
import { Helmet } from "react-helmet"; // Import de Helmet

const Accueil = () => {
  const history = useNavigate();

  const handleActeurClick = (actorId) => {
    // Rediriger vers Acteurdetail.js avec l'ID de l'acteur
    history(`/acteurDetails/${actorId}`);
  };

  const contextValue = {
    handleActeurClick: handleActeurClick,
  };

  return (
    <ActeurContext.Provider value={contextValue}>
      <>
        <Helmet>
          <title>Accueil - MovieFrenzy</title>
          <link rel="canonical" href="https://moviefrenzy.ca/" />
          <meta
            name="description"
            content="Bienvenue sur MovieFrenzy ! Découvrez notre plateforme dédiée aux films, acteurs et dernières nouveautés du cinéma."
          />
        </Helmet>
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundBlendMode: "overlay",
            backgroundColor: "#200211",
            opacity: "0.9",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <NavigationBar />
          <Slider />
          <div style={{ marginTop: "50px" }}>
            <SectionsAccueil />
            <BackToTopButton />
          </div>
          <div style={{ marginTop: "40px" }}>
            <Footer />
          </div>
        </div>
      </>
    </ActeurContext.Provider>
  );
};

export default Accueil;
