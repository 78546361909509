//Imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useNavigate } from "react-router-dom";




const PopularMovies = () => {
  const [popular, setPopular] = useState([]);
  const URL =
    "/api/3/movie/popular?api_key=e16900047627f56531a2d3d8d4df9e92&language=en-US&page=1";
  const history = useNavigate();

  useEffect(() => {
    axios.get(URL).then((res) => {
      const moviesWithPosters = res.data.results.filter(
        (m) => m.poster_path !== null
      );
      const moviesWithoutPosters = res.data.results.filter(
        (m) => m.poster_path === null
      );
      setPopular([
        ...moviesWithPosters,
        ...replaceNoPoster(moviesWithoutPosters),
      ]);
    });
  }, []);

  const replaceNoPoster = (movies) => {
    return movies.map((m) => {
      return {
        ...m,
        poster_path:
          "https://via.placeholder.com/200x300.png?text=Aucune+image+pour+ce+film",
      };
    });
  };

  return (
    <div className="popularMovies">
      <ul className="popularMovies-list">
        {popular.map((p) => {
          return (
            <Movie
              key={p.id}
              movie={p}
              onClickMovie={() => history("/ficheFilm", { id: p.id })}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default PopularMovies;
