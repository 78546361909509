import React, { useState, useEffect, useRef, useCallback } from 'react';
import UseWindowSize from '../components/UseWindowSize';

const MobileCircularNicknameSvg = ({ username }) => {
  const textElementRef = useRef(null);
  const [scale, setScale] = useState(1);
  const { width, height } = UseWindowSize();
  const isMobilePixel7a = width < 413 && height < 733;

  const adjustScaleAndFontSize = useCallback(() => {
    if (username) {
      let charCount = username.length;
      if (charCount >= 20) {
        charCount = 10 + (charCount - 20) / 2;
      }
  
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const maxCharCount = 5;
      const fontSizeBase = 5;
      const scaleBase = 4.5;
  
      let fontSizeReduction = charCount > maxCharCount ? Math.pow(0.9, charCount - maxCharCount) : 1;
      let scaleReduction = charCount > maxCharCount ? Math.pow(0.9, charCount - maxCharCount) : 1;
  
      let fontSize = fontSizeBase * fontSizeReduction / 1.25;
      let newScale = scaleBase * scaleReduction / 1.25;
  
      if (viewportWidth < 361 || (viewportWidth < 376 && viewportHeight < 813)) {
        const extraTens = Math.floor((charCount - 10) / 10);
        for (let i = 0; i < extraTens; i++) {
          fontSize *= 0.6;
          newScale *= 0.6;
        }
      }
  
      if (textElementRef.current) {
        textElementRef.current.style.fontSize = `${fontSize}em`;
      }
      setScale(newScale);
    }
  }, [username]);
  
  useEffect(() => {
    adjustScaleAndFontSize();
    window.addEventListener('resize', adjustScaleAndFontSize);
    return () => window.removeEventListener('resize', adjustScaleAndFontSize);
  }, [username, adjustScaleAndFontSize]);


  return (
    <div className="circularNickNameSvg" style={{
      maxHeight: isMobilePixel7a ? '85.5%' : '100%',
    }}
    >
      <svg width="500" height="500" className='svg7a'>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#000" stopOpacity="1" />
            <stop offset="50%" stopColor="#00FFFF" stopOpacity="1" />
            <stop offset="100%" stopColor="#D90093" stopOpacity="1" />
          </linearGradient>
        </defs>
        <path id="curve" d="M 10,250 A 240,240 0 0,0 490,250" fill="none" />
        <text ref={textElementRef} width="500" fill="url(#gradient)" >
          <textPath href="#curve" startOffset="50%" textAnchor= "middle" className='textPathSvg'>
            {username}
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default MobileCircularNicknameSvg;

// import React, { useState, useEffect, useRef, useCallback } from 'react';

// const MobileCircularNicknameSvg = ({ username }) => {
//   const textElementRef = useRef(null);
//   const [scale, setScale] = useState(1);

//   const adjustScaleAndFontSize = useCallback(() => {
//     if (username) {
//       const charCount = username.length;
//       const viewportWidth = window.innerWidth;

//       // Base values
//       const fontSizeBase = 15; // Base font size in em
//       const scaleBase = 3; // Base scale

//       // Reduction factors
//       const fontSizeReductionFactor = 0.9;
//       const scaleReductionFactor = 0.9;

//       // Calculate reductions
//       const fontSizeReduction = Math.pow(fontSizeReductionFactor, Math.max(charCount - 10, 0));
//       const scaleReduction = Math.pow(scaleReductionFactor, Math.max(charCount - 10, 0));

//       // Calculate new font size and scale
//       const fontSize = fontSizeBase * fontSizeReduction;
//       const newScale = scaleBase * scaleReduction;

//       // Apply new font size and scale
//       if (textElementRef.current) {
//         textElementRef.current.style.fontSize = `${fontSize}em`;
//       }
//       setScale(newScale);
//     }
//   }, [username]);

//   useEffect(() => {
//     adjustScaleAndFontSize();
//     // Add event listener for resizing
//     window.addEventListener('resize', adjustScaleAndFontSize);
//     // Cleanup listener on component unmount
//     return () => window.removeEventListener('resize', adjustScaleAndFontSize);
//   }, [username, adjustScaleAndFontSize]);

//   return (
//     <div className="circularNickNameSvg">
//       <svg width="500" height="500" style={{ transform: `scale(${scale})` }}>
//         <defs>
//           <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
//               <stop offset="0%" stopColor="#000" stopOpacity="1" />
//               <stop offset="50%" stopColor="#00FFFF" stopOpacity="1" />
//               <stop offset="100%" stopColor="#D90093" stopOpacity="1" />
//             </linearGradient>
//         </defs>
//         <path id="curve" d="M 10,250 A 240,240 0 0,0 490,250" fill="none" />
//         <text ref={textElementRef} fill="url(#gradient)">
//           <textPath href="#curve" startOffset="50%" textAnchor="middle">
//             {username}
//           </textPath>
//         </text>
//       </svg>
//     </div>
//   );
// };

// export default MobileCircularNicknameSvg;


// import React, { useEffect, useRef } from 'react';

// const MobileCircularNicknameSvg = ({ username }) => {
//   const textElementRef = useRef(null);

//   useEffect(() => {
//     const adjustFontSize = () => {
      
//       if (!username) return;  // Ajoutez cette ligne pour la vérification

//       // Obtenez l'élément texte du SVG
//       const textElement = textElementRef.current;
  
//       // Calculez le nombre de caractères
//       const charCount = username.length;
  
//       // Trouvez combien de tranches de 10 caractères supplémentaires sont là
//       const extraTens = Math.floor((charCount - 10) / 10);
  
//       // Définissez la taille de la police initiale
//       let fontSize = 4; // 4em est la taille initiale
  
//       // Réduisez la taille de la police de 20 % pour chaque tranche de 10 caractères supplémentaires
//       for (let i = 0; i < extraTens; i++) {
//         fontSize *= 0.65;
//       }
  
//       // Appliquez la nouvelle taille de police à l'élément texte
//       textElement.style.fontSize = `${fontSize}em`;
//     }

//     adjustFontSize();
//   }, [username]);

//   return (
//     <div className="circularNickNameSvg" style={username ? { backgroundImage: 'none' } : {}}>
      // <svg width="500" height="500">
        // <defs>
        //   <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        //     <stop offset="0%" stopColor="#000" stopOpacity="1" />
        //     <stop offset="50%" stopColor="#00FFFF" stopOpacity="1" />
        //     <stop offset="100%" stopColor="#D90093" stopOpacity="1" />
        //   </linearGradient>
        // </defs>
        // <path id="curve" d="M 10,250 A 240,240 0 0,0 490,250" fill="none" />
        // <text ref={textElementRef} width="500" fill="url(#gradient)" >
        //   <textPath href="#curve" startOffset="50%" textAnchor="middle">
        //     {username ? username : ''}
        //   </textPath>
        // </text>
      // </svg>
//     </div>
//   );
// }

// export default MobileCircularNicknameSvg;

