const mapZodiacValueToImageUrl = (value) => {
    const zodiacMapping = {
        "aquarius": "https://zupimages.net/up/23/41/xucf.png",
        "aries": "https://zupimages.net/up/23/41/t314.png",
        "capricorn": "https://zupimages.net/up/23/41/j92j.png",
        "cancer": "https://zupimages.net/up/23/41/2xbb.png",
        "gemini": "https://zupimages.net/up/23/41/6xwq.png",
        "leo": "https://zupimages.net/up/23/41/0ilk.png",
        "libra": "https://zupimages.net/up/23/41/yeo8.png",
        "pisces": "https://zupimages.net/up/23/41/jlxa.png",
        "sagittarius": "https://zupimages.net/up/23/41/g5m1.png",
        "scorpio": "https://zupimages.net/up/23/41/wiyy.png",
        "taurus": "https://zupimages.net/up/23/41/50i8.png",
        "virgo": "https://zupimages.net/up/23/41/zmar.png"
    };
    return zodiacMapping[value] || ""; 
}

const mapGenderValueToDisplay = (value) => {
    const genderMapping = {
        "other": "Autre",
        "male": "Homme",
        "female": "Femme",
        "nonbinary": "Non binaire",
        "preferNotToSay": "Je préfère ne pas répondre"
    };
    return genderMapping[value] || value; 
}

const mapOccupationValueToDisplay = (value) => {
    const occupationMapping = {
        "student": "Étudiant",
        "unemployed": "Chômeur",
        "employed": "Employé",
        "retired": "Retraité",
        "other": "Autre",
        "preferNotToSay": "Je préfère ne pas répondre"

    };
    return occupationMapping[value] || value; 
}



export { mapZodiacValueToImageUrl, mapGenderValueToDisplay, mapOccupationValueToDisplay };
