import React from "react";
import { Link } from "react-router-dom";

const MyFavoritesActors = ({ actor, isHoveredId})  => {
    // const { actor } = props;

    const handleClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="myFavoritesActors">
            <Link to={`/ActeurDetails/${actor.id}`} onClick={handleClick}>
                <img src={actor.profile_path ?
                    `https://image.tmdb.org/t/p/original/${actor.profile_path}`
                    : "https://zupimages.net/up/23/13/yd61.jpg"} alt="actor"
                    style={{border: actor.id === isHoveredId ? "2px solid #FF33CC" : "none"}}
                />
                <div className="conteneur">
                    <li className="titre">{actor.name}</li>
                </div>
            </Link>
        </div>
    )
}

export default MyFavoritesActors;