import React, { useState, useEffect, useContext, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import { DirectionalLight } from 'three';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import CircularNicknameSvg from './CircularNicknameSvg';
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { UserContext } from '../UserContext';
import { defaultBannerUrl, defaultAvatarUrl } from '../constants';
import { useParams } from "react-router-dom";
import { mapZodiacValueToImageUrl } from './/valueToDisplayMappings.js';
import { Country, State } from 'country-state-city';
import YouTube from "react-youtube";
import axios from 'axios';
import PreviewMyFavoritesMovies from "../components/PreviewMyFavoritesMovies";
import MyFavoritesActors from "../components/MyFavoritesActors";
import My3FavoriteCategories from "../components/My3FavoriteCategories";
import UseWindowSize from '../components/UseWindowSize';
import { SRGBColorSpace, ACESFilmicToneMapping } from 'three'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function MyComponent() {
    const [value, setValue] = useState('');

    return <ReactQuill theme="snow" value={value} onChange={setValue} />;
}

function ThreeModel() {
    return (
        <>
            <directionalLight intensity={1} position={[0, 10, 10]} />
            <Model />
        </>
    );
}



function Model() {
    const gltf = useGLTF('/images/cameraTech.glb');
    return <primitive object={gltf.scene} scale={[3, 3, 3]} />;
}


const EmuleProfil = ({ selectedUser }) => {

    const userContext = useContext(UserContext);


    // Utiliser les données du contexte ou les props selon ce qui est disponible
    const userData = selectedUser || userContext.userData;

    const db = getFirestore();
    // console.log("Test avatar 11. db est :", db);

    // const [userData, setUserData] = useState({});
    const [videoKey, setVideoKey] = useState("");
    const [favoritePreviews, setFavoritePreviews] = useState([]);
    const [favoriteActors, setFavoriteActors] = useState([]);
    const [favoriteCategories, setFavoriteCategories] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [zIndex, setZIndex] = useState(40000);
    const [isLoading, setIsLoading] = useState(false);
    const [imageChangeCounter, setImageChangeCounter] = useState(0);
    const { showAnimParam } = useParams();
    const [animationTriggered, setAnimationTriggered] = useState(false);
    const zodiacImageUrl = mapZodiacValueToImageUrl(userData.zodiacSign);
    const countryName = Country.getCountryByCode(userData.country)?.name || userData.country;
    const allStates = State.getStatesOfCountry(userData.country);
    const stateObj = allStates.find(s => s.isoCode === userData.state);
    const stateName = stateObj ? stateObj.name : "Non disponible";

    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');


    const countries = Country.getAllCountries();
    // const states = country ? State.getStatesOfCountry(country.isoCode) : [];
    const states = country ? State.getStatesOfCountry(country) : [];

    const cities = state ? city.getCitiesOfState(country, state) : [];

    const handleCountryChange = (selectedCountryCode) => {
        setCountry(selectedCountryCode);
        setState('');  // Réinitialiser l'état
        setCity('');   // Réinitialiser la ville
    };

    const [showExtraButtons, setShowExtraButtons] = useState(false);

    const toggleExtraButtons = () => {
        setShowExtraButtons(!showExtraButtons);
    };

    const { width, height } = UseWindowSize();

    let videoOpts;

    if (width <= 320) { // iPhone 5/SE 1ère génération
        videoOpts = {
            height: '...',
            width: '...',
            // autres options spécifiques
        };
    } else if (width > 320 && width <= 370) {
        videoOpts = {
            height: '107',
            width: '258',
            // autres options spécifiques
        };
    } else if (width > 370 && width <= 379) {
        videoOpts = {
            height: '115px',
            width: '263px',
            // autres options spécifiques
        };
    } else if (width > 370 && width <= 379 && height >= 812) {
        videoOpts = {
            height: '118px',
            width: '269px',
            // autres options spécifiques
        };
    } else if (width > 379 && width <= 411) {
        videoOpts = {
            height: '125px',
            width: '293px',
            // autres options spécifiques
        };
    } else if (width > 411 && width <= 415) {
        videoOpts = {
            height: '100.75px',
            width: '221px',
            // autres options spécifiques
        };
    } else { // résolutions plus grandes
        videoOpts = {
            height: '310.5px',
            width: '758.875px',
            // options par défaut
        };
    }

    useEffect(() => {
        const fetchYouTubeKey = async () => {
            if (userData.favoriteMovie && userData.favoriteMovie.id) {
                try {
                    const videoResponse = await axios.get(
                        `https://api.themoviedb.org/3/movie/${userData.favoriteMovie.id}/videos?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
                    );
                    const videoData = videoResponse.data.results.find(
                        (video) => video.site.toLowerCase() === "youtube"
                    );
                    if (videoData) {
                        setVideoKey(videoData.key);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de la clé vidéo:", error);
                }
            }
        };

        fetchYouTubeKey();
    }, [userData.favoriteMovie]);


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);


    useEffect(() => {
        const fetchYouTubeKey = async () => {
            if (userData.favoriteMovie && userData.favoriteMovie.id) {
                try {
                    const videoResponse = await axios.get(
                        `https://api.themoviedb.org/3/movie/${userData.favoriteMovie.id}/videos?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
                    );
                    const videoData = videoResponse.data.results.find(
                        (video) => video.site.toLowerCase() === "youtube"
                    );
                    if (videoData) {
                        setVideoKey(videoData.key);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de la clé vidéo:", error);
                }
            }
        };

        fetchYouTubeKey();
    }, [userData.favoriteMovie]);


    return (
        <>
            <div className="emuleProfil"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#200211",
                    opacity: "0.9",
                    overflowX: "hidden",
                    overflowY: "auto",
                    display: "block",
                    zIndex: "1000",
                }}
            >
                <div className="wrapper container-fluid">
                    <div className="row rowBanner">
                        <div className="col-12 col-md-12 colBanner">
                            <div className="banner"
                                style={{
                                    backgroundImage: `url(${userData.banner || defaultBannerUrl})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    margin: "0 0 1,5vh 0",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',

                                }}
                            >
                                <div className="div-circularAvatar"
                                    style={{
                                        backgroundImage: `url(${userData.avatar || defaultAvatarUrl})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        margin: "0 0 1.5vh 0",
                                    }}
                                >
                                    <div className="div-circularNickname" style={{ margin: "0 0 3vh 0" }}>
                                        <CircularNicknameSvg username={userData.username} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row rowBio">
                        <div className="col-12 col-md-12">
                            <div className="div-biseau">
                                <h2 className='titreMabio'>MA BIOGRAPHIE</h2>
                                <span className='spanBio'>
                                    <blockquote className="citationProfil">
                                        <q>{userData.quote}</q>
                                    </blockquote>
                                    <div className='bioText' dangerouslySetInnerHTML={{ __html: userData.biography }}></div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row rowIdentify">
                        <div className="col-12 col-md-12">
                            <div className="identification1">
                                <h2 className='infoPersos'>Mes informations personnelles</h2>
                                <div className='divGender'>
                                    <span>Sexe/Genre :</span>
                                    <span className='inputGender'>
                                        {userData.gender}
                                    </span>
                                </div>
                                <div className='divAge'>
                                    <span>Âge :</span>
                                    <span className='inputAge'>{userData.age}</span>
                                </div>
                                <div className='inputZodiacSignBase'>
                                    <span>Mon signe zodiac :</span>
                                    <figure className='inputZodiacSign'>
                                        <img src={zodiacImageUrl} alt={userData.zodiacSign} />
                                        <figcaption>{userData.zodiacSign}</figcaption>
                                    </figure>
                                </div>
                                <div className='divOccupation'>
                                    <span>Occupation :</span>
                                    <span className='inputOccupation'>
                                        {userData.occupation}
                                    </span>
                                </div>
                                <div className='divPays'>
                                    <span>Mon pays :</span>
                                    <span className='inputPays'>{countryName}</span>
                                </div>
                                <div className='divEtatProvince'>
                                    <span>Ma province :</span>
                                    <span className='inputEtatProvince'>{String(stateName)}</span>
                                </div>
                                <div className='divVille'>
                                    <span>Ma ville :</span>
                                    <span className='inputVille'>{userData.city}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row rowIdentify">
                        <div className="col-12 col-md-12">
                            <div className="identification2">
                                <h2 className='infoIdentify'>Mon profil cinéphile</h2>
                                <div className='preferContainer'>
                                    <h4>Mes films préférés :</h4>
                                    <div className='rowPrefer'>
                                        {userData.favoritePreviews.map(movie => (
                                            <Link to={`/ficheFilm/${movie.id}`} key={movie.id}>
                                                <PreviewMyFavoritesMovies key={movie.id} movie={movie} className='previewEmule' />
                                            </Link>
                                        ))}
                                    </div>
                                    <h4>Mes acteurs préférés :</h4>
                                    <div className='rowPrefer'>
                                        {userData.favoriteActors.map(actor => (
                                            <Link to={`/actorDetails/${actor.id}`} key={actor.id}>
                                                <MyFavoritesActors key={actor.id} actor={actor} />
                                            </Link>
                                        ))}
                                    </div>
                                    <h4>Mes trois genres préférés :</h4>
                                    <div className='rowPrefer rowpreferCategories'>
                                        {userData.favoriteCategories.map(category => (
                                            <My3FavoriteCategories key={category.id} category={category} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row rowFavoritePreview">
                        <div className="col-12 col-md-6">
                            <h3 className='h3CooupCoeur'>Ma preview coup de cœur !</h3>
                            <div className="aspect-ratio-container">
                                {videoKey ? (
                                    <YouTube
                                        videoId={videoKey}
                                        className="iFrameStyle"
                                        opts={videoOpts}
                                    // style={{opacity: '0'}}
                                    />
                                ) : (
                                    <p>Aucun film sélectionné comme coup de cœur.</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row rowEditionProfil">
                        <div className="col-12 col-md-6">
                            <div className="divCircularEditionProfil">
                                <Canvas
                                    gl={{
                                        outputColorSpace: SRGBColorSpace,
                                        toneMapping: ACESFilmicToneMapping,
                                        toneMappingExposure: 1.0,
                                    }}
                                >
                                    <ambientLight intensity={0.5} />
                                    <Suspense fallback={null}>
                                        <ThreeModel />
                                    </Suspense>
                                    <OrbitControls
                                        enableZoom={true}
                                        zoomSpeed={0.6}
                                        minDistance={5}
                                        maxDistance={20}
                                    />
                                </Canvas>
                            </div>
                            <div className="blocMessageInterUser">
                                <div class="wrapperButton" onClick={toggleExtraButtons}>
                                    <input type="checkbox"></input>
                                    <label><i class='icon-off'>Message</i></label>
                                </div>
                                {showExtraButtons && (
                                    <>
                                        <div className="wrapperMessageButton" >
                                            <img src={`${process.env.PUBLIC_URL}/images/messageUserIcon.png`} alt="Message User Icon" />
                                        </div>
                                        <div className="wrapperVidMessageButton"  >
                                            <img src={`${process.env.PUBLIC_URL}/images/messageVidUserIcon.png`} alt="Message Vid User Icon" />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};  // Fin de EmuleProfil

export default EmuleProfil;