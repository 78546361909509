import React, { useEffect, useState, useRef } from "react";
import NavigationBar from "../components/Navigation";
import Footer from "../components/Footer";
import Lottie from 'lottie-react';
import animationData from "../lotties/84036-circle-animation.json";
import meduse from '../video/meduse.mp4';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "../styles/components/Apropos.scss";


const Propos = () => {

  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);
  const [gradientSize, setGradientSize] = useState('0% 100%');


  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".animation-element");
      if (!elements || elements.length === 0) return; // Vérifie que les éléments existent
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight - 100;
        if (isVisible) {
          element.classList.remove("animation-hidden");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
        } else {
          setIsInView(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    const target = document.querySelector('.pulsate-background');
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (sectionRef.current) { // Vérifie que sectionRef.current n'est pas null
      gsap.fromTo(
        sectionRef.current.querySelectorAll('h2, p'), 
        { backgroundSize: '0% 100%' },
        {
          backgroundSize: '100% 100%',
          ease: 'none',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top center',
            end: 'center center',
            scrub: true,
            onEnter: () => console.log('Animation triggered'), // Vérifie dans la console
          },
        }
      );
    }
  }, []);

  return (

    <div className="Apropos"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundBlendMode: "overlay",
        backgroundColor: "#200211",
        opacity: "0.9",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <NavigationBar />
      <section>
        <div id="PinkTown">
          <div id="logoPinkTown"></div>
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            position: "relative",
            height: "auto",
            zIndex: "-10",
            marginTop: "-600px",
            paddingTop: "50px",
            paddingBottom: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "clamp(1.15rem, 2vw, 1.5rem)",
          }}
        // className="pulsate-background"
        >
          <div className="row text-center" style={{ padding: "25px", height: "100%" }}>
            <div className="col-12 col-md-6 d-flex align-items-center"
              style={{
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  margin: "10px",
                }}
                className="paraPropos scroll-reveal"
              >
                <h2 className="text-left title-left" style={{ color: "#200211", textAlign: "left", margin: "8px" }}>QUI SUIS-JE</h2>
                <p className="text-left para-left" style={{ color: "#200211", textAlign: "left", margin: "8px" }}>
                  Créer, développer, améliorer des sites web et des applications à l'aide
                  de ma créativité et mon imagination dans le but de rendre plus visibles
                  et accessibles les projets de mes clients est une véritable
                  passion pour moi.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center">
              <div
                style={{
                  textAlign: "center",
                  margin: "10px",
                  marginTop: "60px",
                }}
                className="paraPropos scroll-reveal"
              >
                <h2 className="text-left title-left" style={{ color: "#200211", textAlign: "left", margin: "10px" }}>POURQUOI ME CHOISIR?</h2>
                <p className="text-left para-left" style={{ color: "#200211", textAlign: "left", margin: "10px" }}>`
                  Persévérant, passionné avec un bon zeste de créativité, je considère
                  les embûches comme des leviers pour progresser et me stimulent à aller
                  de l'avant. Je suis un développeur web et mobile qui aime relever des
                  défis et qui est toujours à la recherche de nouvelles technologies pour
                  les apprivoiser et me surpasser dans mon travail.
                  <br></br>
                  Je suis
                  <span
                    style={{ color: "#ff33cc", fontFamily: "'Ralins Script', cvursive !important", fontSize: "clamp(1.5rem, 4vw, 2.35rem)", fontWeight: "bold", fontStyle: "italic" }}
                  >
                    {" "}Philippe Bolduc
                  </span>
                  , designer web.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            style={{
              backgroundImage: "conic-gradient(from -190deg at 50% 50%, #000000, #D90093",
              height: '100%',
              position: "relative",
              width: "100%",
              zIndex: "-100",
            }}
            className="col-12 col-md-6 d-flex align-items-center w-100"
          >
            <div className="row"
              style={{
                position: 'relative',
                zIndex: '-500',
                width: '100%',
                margin: '0 auto',
              }}
            >
              <div style={{
                width: '100wh',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
              }}>
                <video width="100%" height="100%" autoPlay muted loop playsInline
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    width: '100wh',
                    height: '100vh',
                    position: 'absolute',
                    top: 0,
                    borderRadius: '50%',
                  }}>
                  <source src={meduse} type="video/mp4" className="background-image-class" />
                  Votre navigateur ne prend pas en charge la vidéo.
                </video>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(32, 2, 17, 0.85)',
                  margin: '0 auto',
                }}>
                </div>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}>
                </div>
              </div>
            </div>
            <div>
              <h1
                style={{
                  color: "#D90093",
                  fontFamily: "Bebas Neue",
                  fontSize: "clamp(3rem, 8vw, 5.5rem)",
                  textAlign: "center",
                  position: "absolute",
                  left: "25%",
                  top: "75px",
                  width: "50%",
                  zIndex: "150",
                  textShadow: "0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #D90093, 0 0 70px #D90093, 0 0 80px #D90093, 0 0 100px #D90093, 0 0 150px #D90093",
                  backgroundColor: "rgba(255, 255, 255, 0.15)",
                }}
                className="strokeImaginatif"
              >
                IMAGINATIF
              </h1>
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                onComplete={() => console.log("Animation loaded")}
                onError={(err) => console.log(err)}
                style={{ transform: "scale(2.75)", zIndex: "100", display: "flex", justifyContent: "center", alignItems: "center" }}
              />
              <div className="qualite">
                <span
                  className="forgeron"
                  style={{
                    position: "absolute",
                    top: "-22%",
                    left: "-130%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    height: "80px",
                    width: "480px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FF33CC",
                      fontFamily: "Bebas Neue",
                      fontSize: "2.35rem",
                    }}
                  >
                    FORGERON DE LA CRÉATIVITÉ
                  </span>
                </span>
                <span
                  className="perseverance"
                  style={{
                    position: "absolute",
                    top: "-35%",
                    left: "135%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    height: "270px",
                    width: "270px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FF33CC",
                      fontFamily: "Bebas Neue",
                      fontSize: "2.85rem",
                    }}
                  >
                    PERSÉVÉRANCE
                  </span>
                </span>
                <span
                  className="determination"
                  style={{
                    position: "absolute",
                    top: "68%",
                    left: "156%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    height: "370px",
                    width: "370px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FF33CC",
                      fontFamily: "Bebas Neue",
                      fontSize: "3.65rem",
                    }}
                  >
                    DÉTERMINATION
                  </span>
                </span>
                <span
                  className="resilience"
                  style={{
                    position: "absolute",
                    top: "87%",
                    left: "-105%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    height: "265px",
                    width: "265px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FF33CC",
                      fontFamily: "Bebas Neue",
                      fontSize: "2.5rem",
                    }}
                  >
                    RÉSILIENCE
                  </span>
                </span>
                <span
                  className="visionnaire"
                  style={{
                    position: "absolute",
                    top: "126%",
                    left: "30%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "50%",
                    height: "120px",
                    width: "180px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FF33CC",
                      fontFamily: "Bebas Neue",
                      fontSize: "1.95rem",
                    }}
                  >
                    VISIONNAIRE
                  </span>
                </span>
              </div>
              <div className="qualiteMobile">
                <span className="forgeronMob animation-hidden animation-element">FORGERON DE LA CRÉATIVITÉ</span>
                <span className="perseveranceMob animation-hidden animation-element">PERSÉVÉRANCE</span>
                <span className="determinationMob animation-hidden animation-element">DÉTERMINATION</span>
                <span className="resilienceMob animation-hidden animation-element">RÉSILIENCE</span>
                <span className="visionnaireMob animation-hidden animation-element">VISIONNAIRE</span>
              </div>
            </div>
          </div>
          <h3 className="h3ServiceA">LE THAUMATURGE DU NUMÉRIQUE...</h3>
          <div
            className="col-12 col-md-6 d-flex align-items-center background-image-class pulsate-background"
            style={{
              backgroundImage: "url('https://zupimages.net/up/23/15/ozt2.png')",
              height: isInView ? "1080px" : "810px",
              position: "relative",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: isInView ? "100%" : "85%",
              margin: "5% auto 5% auto",
              borderRadius: "5%",
              transform: isInView ? 'scale(0.95)' : 'scale(0.75)',
              border: isInView ? '5px solid #D90093' : 'none',
              boxShadow: isInView ? '0 0 10px #00FFFF, 0 0 20px #00FFFF, 0 0 30px #00FFFF, 0 0 40px #00FFFF, 0 0 70px #00FFFF, 0 0 80px #00FFFF, 0 0 100px #00FFFF, 0 0 150px #00FFFF' : 'none',
              transition: 'transform 0.75s ease', // Animation
            }}
          >
            <h1 className="strokeThaumaturge">THAUMATURGE DU NUMÉRIQUE, À VOTRE SERVICE !</h1>
          </div>
          <h3 className="h3ServiceB">À VOTRE SERVICE !</h3>
        </div>
        <div
          ref={sectionRef}
          className="scroll-reveal2"
          style={{
            backgroundImage: `linear-gradient(90deg, #D90093, #00FFFF)`,
            backgroundClip: 'text',
            color: 'transparent',
            backgroundSize: '0% 100%',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          <h2 className="section-title"><span>Découvrez Mon Expertise</span></h2>
          <p>
            <span>
              En tant que développeur web déterminé et passionné, je façonne l'expérience numérique avec finesse et innovation...
            </span>
          </p>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Propos;



