import React, { useState } from 'react';
import { useRive } from '@rive-app/react-canvas';
import WaveMovieRiv from './wave_animation_effect2.riv';
import UseWindowSize from '../components/UseWindowSize';

export function WaveMovie() {
    const { width } = UseWindowSize();
    const isMobile = width < 768;
    const isMobilSmaller = width < 401;
    const isMobilMoreSmaller = width < 376;
    const isMobilUltraMoreSmaller = width < 361;
    const [hover, setHover] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const marginRightValue = isMobile ? '3.5vw' : isLoggedIn ? '3.5vw' : '7.5vw';
    const marginTopValue = isMobile ? '-10.5vh' : '4.5vh';

    const { rive, RiveComponent } = useRive({
        src: WaveMovieRiv,
        autoplay: true,
    });

    const hoverStyles = hover ? {
        filter: 'brightness(1)',
        dropShadow: isMobile ? '0 0 7.5px #00FFFF' : '0 0 10px #00FFFF',
        border: 'solid #00FFFF',
        boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
        transform: 'scale(1.2)',
        transitionDuration: '0.25s'
    } : {};

    return (
        <RiveComponent 
            style={{
                width: '45%',
                height:'15.25vh',
                border: '2px solid #D90093',
                borderTopColor: '#00FFFF',
                borderBottomColor: '#D90093',
                borderLeftColor: '#00FFFF',
                boxShadowBottom: hover ? '0 0 10px rgba(0, 255, 255, 0.5)' : '0 0 5px rgba(0, 255, 255, 0.25)',
                boxShadowTop: hover ? '0 0 10px rgba(217, 0, 147, 0.5)' : '0 0 5px rgba(217, 0, 147, 0.25)',
                borderRadius: '50%',
                cursor: 'pointer',
                msTransitionDuration: '0.25s',
                marginRight: marginRightValue,
                marginTop: marginTopValue,
                overflow: 'hidden',
                marginLeft: '32.25vw',
                zIndex: '-1000',
                transform: isMobilUltraMoreSmaller ? 'scale(0.55) translateX(33.5vw) translateY(50vh)' : isMobilMoreSmaller ? 'scale(0.55) translateX(33.5vw) translateY(45vh)' : isMobilSmaller ? 'scale(0.55) translateX(33.5vw) translateY(40vh)' :  isMobile ? 'scale(0.55) translateX(33.5vw) translateY(33.5vh)' : 'scale(1.0) translateY(0vw) translateY(0vh)',
                transition: 'all 0.15s ease-in-out',
                filter: hover ? 'drop-shadow(0px 0px 10px #D90093)' : 'none',
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />
    );
}

