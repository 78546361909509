import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { auth } from './firebasedeconfig.js';
import LoadingSpinner from './components/LoadingSpinner.js';
import UserContextProvider from './UserContext.js';
import OnlineUsersProvider from './OnlineUsersContext.js';
import AnimationTriggerUpdater from './components/AnimationTriggerUpdater.js';
import { RefreshProvider } from './components/RefreshContext.js';
import { AnimationProvider } from './components/AnimationContext.js';
import AppRoutes from './routes.js'; // Import avec extension .js

const AppContent = () => {
  const location = useLocation(); // Utilisation correcte de useLocation ici

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://moviefrenzy.ca/${location.pathname}`}
          key={`canonical:${location.pathname}`}
        />
        <meta
          name="description"
          content="Bienvenue sur MovieFrenzy, votre plateforme ultime pour découvrir les films les plus populaires et toutes les informations sur vos acteurs préférés !"
        />
      </Helmet>
      <AnimationTriggerUpdater />
      <AppRoutes /> {/* Les routes sont maintenant importées depuis un fichier séparé */}
    </>
  );
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      setIsLoading(false); // Stop le spinner après vérification
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <RefreshProvider>
      <UserContextProvider>
        <OnlineUsersProvider>
          <AnimationProvider>
            <Router>
              <AppContent /> {/* AppContent est maintenant enfant du Router */}
            </Router>
          </AnimationProvider>
        </OnlineUsersProvider>
      </UserContextProvider>
    </RefreshProvider>
  );
};

export default App;
