import React from "react";
import { Link } from "react-router-dom";

const PreviewMyFavoritesMovies = ({ movie, isHoveredId})  => {
    // const { movie } = props;

    const handleClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="previewMyFavoritesMovies">
            <Link to={`/ficheFilm/${movie.id}`} onClick={handleClick}>
                <img src={movie.poster_path ?
                    `https://image.tmdb.org/t/p/original/${movie.poster_path}`
                    : "https://zupimages.net/up/23/13/yd61.jpg"} alt="poster" 
                    style={{border: movie.id === isHoveredId ? "2px solid #FF33CC" : "none"}}
                    />
                <div className="conteneur">
                    <li className="titre">{movie.title}</li>
                </div>
            </Link>
        </div>
    )
}

export default PreviewMyFavoritesMovies;
