import React, { useContext, useState, useEffect } from 'react';
import { OnlineUsersContext } from './OnlineUsersContext';
import Modal from 'react-bootstrap/Modal';
import EmuleProfil from './components/EmuleProfil';
import styles from './styles/components/onlinelateralemule.scss';

const OnlineUsersSidebar = ({ isOpen, closeSidebar }) => {
  const { onlineUsers, userId } = useContext(OnlineUsersContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      const checkIfMobile = () => {
          setIsMobile(window.innerWidth <= 500);
      };

      // Vérifier immédiatement et ensuite à chaque redimensionnement
      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);

      // Nettoyage de l'écouteur d'événements
      return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hover, setHover] = useState(false);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className={`online-users-sidebar ${isOpen ? 'open' : ''}`} style={{ zIndex: '10000' }} >
      <div className="onlinelateral">
        <button onClick={closeSidebar}>Fermer</button>
        <ul style={{margin: "0.5rem"}}>
          {onlineUsers.map(user => (
            <li key={user.uid} onClick={() => handleUserClick(user)}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{ backgroundColor: user.uid === userId ? '#D90093' : hover ? 'rgba(0,255,255, 0.3)' : '', cursor: 'pointer'}}
            >
              <img src={user.avatar} alt={`${user.username}'s avatar`} />
              <span>{user.username}</span>
            </li>
          ))}
        </ul>
        <Modal show={showModal} onHide={() => setShowModal(false)} className="modalProfil"  style={{opacity: '100%', width: '100%', minHeight: '100%', height: '100%' }}>
          <Modal.Dialog id="custom-modal" style={{ maxWidth: '70vw', height: '100%', maxHeight: '100%', backgroundColor: '#00FFFF', border: '3px solid #FFFFFF', boxShadow: '8px 4px 4px #00FFFF', overflowY:'hidden' }}>
            <Modal.Header style={{ color: '#D90093', backgroundColor: '#000' }}>
              <Modal.Title>{selectedUser ? selectedUser.username : ''}</Modal.Title>
              <Modal.Title>{selectedUser ? selectedUser.occupation : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{  backgroundColor: '#200211' }}>
              <EmuleProfil selectedUser={selectedUser} />
            </Modal.Body>
          </Modal.Dialog>
        </Modal>
      </div>
    </div>
  );
};

export default OnlineUsersSidebar;






