import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import '../styles/components/Genre.scss';

const Genre = ({ id, name }) => {
  const history = useNavigate();
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  const handleCategoryClick = () => {
    history(`/categories/${id}`, { id, name });
  };

  return (
    <span className="genre" style={isDesktopOrLaptop ? { margin: '10px' } : { margin: '5px' }} onClick={handleCategoryClick}>
      {name}
    </span>
  );
};

export default Genre;




