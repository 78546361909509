const PreviewMovie = (props) => {
    const { movie, onClickMovie } = props;

    return (
        <div className="movie" onClick={() => onClickMovie(movie)}>
            <img src={movie.poster_path ?
                `https://image.tmdb.org/t/p/original/${movie.poster_path}`
                : "https://zupimages.net/up/23/13/yd61.jpg"} alt="poster" />
            <div className="conteneur">
                <li className="titre">{movie.title}</li>
            </div>
        </div>
    )
}

export default PreviewMovie;
