
import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import style from "../styles/components/EditTransition.scss";


// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);


const EditTransition = ({ isSaveClicked }) => {
    const svgRef = useRef(null);
    const revealRef = useRef(null);



    useEffect(() => {
        // GSAP Timeline
        const timelinevar = gsap.timeline({ defaults: { ease: "power4.out", duration: 0.7 } });

        // Initial setup
        gsap.set("g", { autoAlpha: 1 });
        gsap.from("#_Edition_", { xPercent: -10 });
        gsap.to("svg", { scale: 1.2, duration: 4 });
        gsap.set(revealRef.current, { scaleY: 1, transformOrigin: 'bottom' });


        timelinevar.from("#_Edition_ .cls-1", {
            yPercent: -200,
            stagger: 0.03,
            skewY: 60,
            skewX: 30,
            scaleY: 0.9,
            opacity: 0
        });
        timelinevar.from('#_Finalisee_ .cls-1', {
            yPercent: 215,
            stagger: .03,
            skewY: 60,
            skewX: 30,
            scaleY: .5,
            opacity: 0
        }, "-=.7")
        timelinevar.to('#_Finalisee_ .cls-1', {
            xPercent: 0,
            ease: 'elastic.out(1, 0.5)'
        }, "1.3")
        timelinevar.from('#_Arrow_ .cls-1', {
            opacity: 0,
            ease: 'elastic.out(1, 0.5)'
        }, "-=.7")
        timelinevar.to('#_Edition_ .cls-1', {
            stagger: .03,
            scaleX: 0,
            skewX: 30,
            x: 30,
            opacity: 0,
            duration: .2
        }, "+=1")
        timelinevar.to('#_Finalisee_ .cls-1', {
            stagger: .03,
            scaleX: 0,
            skewX: 30,
            x: 30,
            opacity: 0,
            duration: .2
        }, "<")
        timelinevar.to('#_Arrow_ .cls-1', {
            xPercent: 200,
            // opacity: 1,
            duration: 1.3,
            ease: 'elastic.out(1, 0.3)'
        }, "<")
        timelinevar.to('#_Arrow_ .cls-1', {
            rotateZ: -90,
            transformOrigin: 'center center',
        }, "-=.1")
        timelinevar.to('#_Arrow_ .cls-1', {
            yPercent: 1000,
            duration: 1.3,
        }, "-=.5")
        timelinevar.to('#_Arrow_ .cls-1', {
            yPercent: -2690,
            ease: 'back.in(1)',
            duration: .5,
        }, "-=.3")
        timelinevar.to('.reveal', {
            scaleY: 0,
            transformOrigin: 'top',
            duration: 1.2,
            ease: 'power4.inOut'
        }, "-=.6")
   


        return () => {
            timelinevar.kill(); // Arrête et détruit la timeline, ainsi que toutes les animations qu'elle contient.
        };


    }, [isSaveClicked]);


    // }, [isSaveClicked]); // Dependency array for the effect

    return (
        <div classNameNameName="editTransition" style={{ width: "100%", height: "100%" }}>

            <svg x="0px" y="0px" viewBox="0 0 1920 1080"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "9999",
                    overflow: "hidden",
                    pointerEvents: "none",
                    fill: "#D90093",
                    transform: "Scale(0.05)"
                }}
            >

                <g id="_Edition_"><path classNameName="cls-1" d="m129.89,255.73h161.62v43.1h-102.36v79.74h81.35v43.1h-81.35v92.67h102.36v43.1h-161.62V255.73Zm86.2-69.82h64.65l-57.64,49.57h-44.72l37.71-49.57Z"/><path classNameName="cls-1" d="m325.45,255.73h90.51c59.26,0,88.35,26.29,88.35,74.56v152.58c0,48.27-29.09,74.56-88.35,74.56h-90.51V255.73Zm89.43,258.6c18.86,0,30.17-7.76,30.17-29.31v-156.89c0-21.55-11.31-29.31-30.17-29.31h-30.17v215.5h30.17Z"/><path classNameName="cls-1" d="m544.18,255.73h59.26v301.7h-59.26V255.73Z"/><path classNameName="cls-1" d="m693.94,298.83h-61.95v-43.1h183.17v43.1h-61.95v258.6h-59.26v-258.6Z"/><path classNameName="cls-1" d="m843.71,255.73h59.26v301.7h-59.26V255.73Z"/><path classNameName="cls-1" d="m942.83,485.89v-158.61c0-48.27,31.79-75.86,89.97-75.86s89.97,27.58,89.97,75.86v158.61c0,48.27-31.79,75.86-89.97,75.86s-89.97-27.58-89.97-75.86Zm120.68,3.02v-164.64c0-21.55-11.85-29.74-30.71-29.74s-30.71,8.19-30.71,29.74v164.64c0,21.55,11.85,29.74,30.71,29.74s30.71-8.19,30.71-29.74Z"/><path classNameName="cls-1" d="m1162.64,255.73h74.35l57.64,180.59h1.08v-180.59h52.8v301.7h-60.88l-71.11-220.24h-1.08v220.24h-52.79V255.73Z"/></g>
                <g id="_Finalisee_"><path classNameName="cls-1" d="m243.59,604.17h156.77v43.1h-97.51v84.05h76.5v43.1h-76.5v131.46h-59.26v-301.7Z"/><path className="cls-1" d="m428.92,604.17h59.26v301.7h-59.26v-301.7Z"/><path className="cls-1" d="m532.35,604.17h74.35l57.64,180.59h1.08v-180.59h52.79v301.7h-60.88l-71.11-220.24h-1.08v220.24h-52.8v-301.7Z"/><path className="cls-1" d="m808.18,604.17h80.27l61.42,301.7h-59.26l-10.77-59.91v.86h-67.34l-10.77,59.05h-54.95l61.41-301.7Zm64.65,201.71l-26.4-149.13h-1.08l-25.86,149.13h53.33Z"/><path className="cls-1" d="m978.42,604.17h59.26v258.6h97.51v43.1h-156.77v-301.7Z"/><path className="cls-1" d="m1163.74,604.17h59.26v301.7h-59.26v-301.7Z"/><path className="cls-1" d="m1256.94,834.33v-17.24h56.03v20.69c0,21.55,11.85,29.31,30.71,29.31s30.71-7.76,30.71-30.17c0-25-11.85-40.95-50.64-68.1-49.56-34.91-65.73-59.48-65.73-93.96,0-47.41,30.71-74.99,88.35-74.99s86.2,27.58,86.2,75.86v12.5h-56.03v-15.52c0-21.55-10.77-29.74-29.63-29.74s-29.63,8.19-29.63,28.88c0,21.98,12.39,37.93,51.18,65.08,49.56,34.91,65.19,59.05,65.19,96.55,0,49.13-31.25,76.72-89.43,76.72s-87.28-27.58-87.28-75.86Z"/><path className="cls-1" d="m1467.59,604.17h161.62v43.1h-102.36v79.74h81.35v43.1h-81.35v92.67h102.36v43.1h-161.62v-301.7Zm86.2-69.82h64.65l-57.64,49.57h-44.72l37.71-49.57Z"/><path className="cls-1" d="m1663.15,604.17h161.62v43.1h-102.36v79.74h81.35v43.1h-81.35v92.67h102.36v43.1h-161.62v-301.7Z"/></g>
                <g id="_Arrow_"><polygon className="cls-1" points="376.49 727.13 329.16 703.86 329.16 732.33 105.2 732.33 105.2 776.49 329.16 776.49 329.16 802.23 376.49 778.96 423.81 750.39 376.49 727.13"/></g>
            </svg>
            <div classNameName="reveal" ref={revealRef}></div>
        </div>
    );
};

export default EditTransition;
