import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import animationData_Stvalentin_pulse2 from '../lotties/st-Valentin-heart-pulse_01.json';
import animationData_Stvalentin_Butterfly from '../lotties/st-Valentin-butterFly_01.json';
import animationData_Noel_tree from '../lotties/noel_Lottie_chrismasTree_01.json';
import animationData_Noel_snowflakes from '../lotties/noel_Lottie_flocons_02.json';
import animationData_NewYear_clock from '../lotties/newYear_Lottie_Clock_01.json';
import animationData_NewYear_fireWorks_01 from '../lotties/newYear_Lottie_fireWorks_02.json';
import animationData_Halloween_2 from '../lotties/halloween_Lottie_Bat_02.json';
import animationData_Halloween_3 from '../lotties/halloween_Lottie_Spider_03.json';
import animationData_Easter_ChickEgg from '../lotties/easter_Lottie_eggChick_01.json';
import animationData_STJean_Harfang from '../lotties/st_Jean_Lottie_Harfang.json';
import animationData_Default_passion_Cinema from '../lotties/passionCinema_01.json';
import animationData_CanadaDay_01 from '../lotties/cosmiqueCanadaBeaver_01.json';
import animationData_USADay_Eagle_01 from '../lotties/USA_EagleFlag_01.json';
import animationData_FranceDay_01 from '../lotties/France_Eiffel_01.json';
import animationData_FranceDay_02 from '../lotties/France_Flag_02.json';
import animationData_FranceDay_03 from '../lotties/France_Map_03.json';
import animationData_UserHoliday_ZodiacWheel from '../lotties/userHoliday_ZodiacWheel_01.json';
import Movie from './Movie';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../UserContext';
import { RefreshContext } from '../components/RefreshContext';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebasedeconfig';
import { useAnimationContext } from './AnimationContext';
import BackToTopButton from './BackToTopButton';
import MyParticlesComponent from './tsparticles';
import { filter } from 'minimatch';
import {
    Circle,
    Triangle,
    Rhombus,
    Parallelogram,
    Polygon,
} from 'react-awesome-shapes';
import { GradientTexture } from '@react-three/drei';
import { GreaterCompare } from 'three';
import { fill } from 'three/src/extras/TextureUtils.js';
import { useMediaQuery } from 'react-responsive'; // Importer le hook pour les requêtes média ??
import { Scale } from 'chart.js';

const SectionsAccueil = () => {
    const { triggerAnimation } = useAnimationContext();
    const [hasAnimatedA, setHasAnimatedA] = useState(false);
    const [hasAnimatedB, setHasAnimatedB] = useState(false);
    const [animationTrigger, setAnimationTrigger] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const LottieCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;  // Permet de positionner les éléments de manière précise
    width: 100%;
    height: 72.5vh;
    background-image: ${(props) => props.isUserZodiacPeriod ? `url(${props.zodiacBackground})` : 'none'};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1 !important;
    margin-bottom: -31.5rem;
`;

    const ZodiacContainer = styled.div`
    position: absolute;
    top: 10%;  // Positionne l'image en fonction de l'écran
    right: 5%;  // Ajuste cette valeur pour positionner à droite
    display: flex;
    flex-direction: column;
    align-items: center;
`;



    // States pour les films
    const [holidayFilms, setHolidayFilms] = useState([]);
    const [tendancesFilms, setTendancesFilms] = useState([]);
    const [meilleursChoixFilms, setMeilleursChoixFilms] = useState([]);
    const [saintValentin, setSaintValentin] = useState([]);
    const [currentHoliday, setCurrentHoliday] = useState('');
    const [userHolidayFilms, setUserHolidayFilms] = useState([]);
    const [currentVisual, setCurrentVisual] = useState({});
    const { userId } = useContext(UserContext);
    const { username, setUsername, isLoggedIn, userIsLoggedIn } = useContext(UserContext);
    const [userData, setUserData] = useState({});
    const { refreshNeeded, setRefreshNeeded } = useContext(RefreshContext);
    const history = useNavigate();
    const isHalloween = currentHoliday.toLowerCase() === "l'halloween";
    const isStJean = currentHoliday === "la Saint-Jean-Baptiste";
    const isEaster = currentHoliday === "la Pâques";
    const isValentine = currentHoliday === "la Saint-Valentin";
    const isFranceDay = currentHoliday === "la Fête Nationale (France)";
    const isHalloween_Cimeterry = currentHoliday.toLowerCase() === "l'halloween";
    const isIndependanceDay = currentHoliday === "la Fête Nationale (États-Unis)";
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredRight, setIsHoveredRight] = useState(false);
    const default_genre_ids = "28,12,16"; // Action, Aventure, Animation (ou les genres que tu veux par défaut)
    const userZodiac = userData.zodiacSign;  // Par exemple: 'aries'
    const isUserZodiacPeriod = currentHoliday === "la fête de l'utilisateur";
    const [isUserZodiacPeriodActive, setIsUserZodiacPeriodActive] = useState(false);

    const [isMobileSmall, setIsMobileSmall] = useState(window.innerWidth < 481);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = () => {
                setIsMobileSmall(window.innerWidth < 1401);
            };

            window.addEventListener('resize', handleResize);
            handleResize(); // Appel initial pour définir l'état

            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    // Déterminez la classe de fête actuelle
    const holidayClass = currentHoliday === "l'Halloween" ? 'isHalloween' :
        currentHoliday === "la Fête Nationale (États-Unis)" ? 'isIndependenceDay' :
            currentHoliday === "la Saint-Jean-Baptiste" ? 'isStJean' :
                currentHoliday === "la Pâques" ? 'isEaster' :
                    currentHoliday === "la Saint-Valentin" ? 'isValentine' :
                        currentHoliday === "la Fête Nationale (France)" ? 'isFranceDay' :
                            '';



    const zodiacPeriods = {
        "aries": { startMonth: 3, startDay: 21, endMonth: 4, endDay: 19 },
        "taurus": { startMonth: 4, startDay: 20, endMonth: 5, endDay: 20 },
        "gemini": { startMonth: 5, startDay: 21, endMonth: 6, endDay: 20 },
        "cancer": { startMonth: 6, startDay: 21, endMonth: 7, endDay: 22 },
        "leo": { startMonth: 7, startDay: 23, endMonth: 8, endDay: 22 },
        "virgo": { startMonth: 8, startDay: 23, endMonth: 9, endDay: 22 },
        "libra": { startMonth: 9, startDay: 23, endMonth: 10, endDay: 22 },
        "scorpio": { startMonth: 10, startDay: 23, endMonth: 11, endDay: 21 },
        "sagittarius": { startMonth: 11, startDay: 22, endMonth: 12, endDay: 21 },
        "capricorn": { startMonth: 12, startDay: 22, endMonth: 1, endDay: 19 },
        "aquarius": { startMonth: 1, startDay: 20, endMonth: 2, endDay: 18 },
        "pisces": { startMonth: 2, startDay: 19, endMonth: 3, endDay: 20 }
    };

    const zodiacTranslations = {
        "aries": "du Bélier",
        "taurus": "du Taureau",
        "gemini": "du Gémeaux",
        "cancer": "du Cancer",
        "leo": "du Lion",
        "virgo": "de la Vierge",
        "libra": "de la Balance",
        "scorpio": "du Scorpion",
        "sagittarius": "du Sagittaire",
        "capricorn": "du Capricorne",
        "aquarius": "du Verseau",
        "pisces": "des Poissons"
    };

    const zodiacIcons = {
        "aquarius": "https://zupimages.net/up/24/40/tl6v.png",   // Verseau ok
        "aries": "https://zupimages.net/up/24/40/b9ni.png",      // Bélier ok
        "capricorn": "https://zupimages.net/up/24/40/ymmj.png",  // Capricorne ok
        "cancer": "https://zupimages.net/up/24/40/wd4o.png",     // Cancer ok
        "gemini": "https://zupimages.net/up/24/40/tbk8.png",     // Gémeaux ok
        "leo": "https://zupimages.net/up/24/40/kwjb.png",        // Lion ok
        "libra": "https://zupimages.net/up/24/40/kow9.png",      // Balance ok
        "pisces": "https://zupimages.net/up/24/40/oza3.png",     // Poissons ok
        "sagittarius": "https://zupimages.net/up/24/40/d9jv.png",// Sagittaire ok
        "scorpio": "https://zupimages.net/up/24/40/codd.png",    // Scorpion ok
        "taurus": "https://zupimages.net/up/24/40/tuvp.png",     // Taureau ok
        "virgo": "https://zupimages.net/up/24/40/j3zu.png"       // Vierge ok
    };

    const zodiacBackgrounds = {
        "aquarius": "https://zupimages.net/up/24/39/ihhk.png", // Verseau ok
        "aries": "https://zupimages.net/up/24/39/c2zh.png", // Bélier ok
        "taurus": "https://zupimages.net/up/24/39/w3ff.png", // Taureau ok
        "gemini": "https://zupimages.net/up/24/39/79wa.png", // Gémeaux ok
        "cancer": "https://zupimages.net/up/24/40/4xeo.png", // Cancer ok
        "leo": "https://zupimages.net/up/24/40/yv51.png", // Lion ok
        "virgo": "https://zupimages.net/up/24/40/qx3f.png", // Vierge ok
        "libra": "https://zupimages.net/up/24/39/w3iz.png",  // Balance ok
        "scorpio": "https://zupimages.net/up/24/39/ybam.png", // Scorpion ok
        "sagittarius": "https://zupimages.net/up/24/39/xxy2.png", // Sagittaire ok
        "capricorn": "https://zupimages.net/up/24/39/s826.png", // Capricorne ok
        "pisces": "https://zupimages.net/up/24/39/hsyn.png" // Poissons ok
    };

    const zodiacLogoText = {
        // aquarius: "https://zupimages.net/up/24/40/hzkk.png", // Aquarius ok
        aquarius: "https://zupimages.net/up/24/40/wt8o.png", // Verseau ok
        // aries: "https://zupimages.net/up/24/40/3t52.png", // Aries ok
        aries: "https://zupimages.net/up/24/40/bigw.png", // Bélier ok ?
        cancer: "https://zupimages.net/up/24/40/jeyz.png",
        // capricorn: "https://zupimages.net/up/24/40/342j.png", // Capricorn ok
        capricorn: "https://zupimages.net/up/24/41/2aye.png", // Capricorne ok
        // gemini: "https://zupimages.net/up/24/41/r68v.png", // Gemini ok
        gemini: "https://zupimages.net/up/24/41/7746.png", // Gémeaux ok
        // leo: "https://zupimages.net/up/24/41/100m.png", // Leo ok
        leo: "https://zupimages.net/up/24/41/rjkv.png", // Lion ok
        // libra: "https://zupimages.net/up/24/40/6dxu.png", // Libra ok
        libra: "https://zupimages.net/up/24/40/iazn.png", // Balance ok 
        // pisces: "https://zupimages.net/up/24/41/itvm.png", // Pisces ok 
        pisces: "https://zupimages.net/up/24/41/ax0u.png", // Poissons ok
        // sagittarius: "https://zupimages.net/up/24/41/2pga.png", // Sagittarius ok
        sagittarius: "https://zupimages.net/up/24/41/kc95.png", // Sagittaire ok
        // scorpio: "https://zupimages.net/up/24/41/mizp.png", // Scorpio ok
        scorpio: "https://zupimages.net/up/24/41/dek3.png", // Scorpion ok
        // taurus: "https://zupimages.net/up/24/41/evv5.png", // Taurus ok
        taurus: "https://zupimages.net/up/24/41/kudl.png",  // Taureau ok
        // virgo: "https://zupimages.net/up/24/41/kyz1.png", // Virgo ok
        virgo: "https://zupimages.net/up/24/41/r4nk.png", // Vierge ok
    };



    // Holiday : visuel Méthode 6

    const holidayVisuals = {
        "le Noël": {
            background: "linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(0, 255, 255, 0.7))",
            lottie_noel_1: animationData_Noel_snowflakes,
            lottie_noel_2: animationData_Noel_tree,
            icon1: "https://zupimages.net/up/24/36/67j0.png",
            dividerClass: "shapedividers_com-8362", // Ajout de la classe pour le diviseur de Noël (#FFFFFF)
            borderStyle: "3px solid rgba(255, 51, 204, 0.9)",
            titleColor: "#FFFFFF", // Couleur du titre pour Noël
            borderTitleHoliday: "5px solid rgba(255, 255, 255, 0.9)", // Bordure rose pour Noël (#FFFFFF)
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour le titre de Noël
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour l'icône
        },
        "l'Halloween": {
            background: "linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(255, 95, 31, 0.7))", //Gradient Black & Neon Carrot (Orange)
            lottie_Halloween: animationData_Halloween_2,
            lottie_Halloween_2: animationData_Halloween_3,
            icon1: "https://zupimages.net/up/24/36/7zig.png",
            icon2: "https://zupimages.net/up/24/37/ppqy.png",
            dividerClass: "shapedividers_com-6475",  // Classe pour Halloween (#000000)
            borderStyle: "3px solid rgba(255, 85, 31, 0.9)",  // Bordure pour vignette orange pour Halloween
            titleColor: "#00FFFF", // Couleur du titre pour Halloween
            titleColorVignette: "#FF5F1F", // Couleur du titre pour Halloween
            borderTitleHoliday: "5px solid rgba(0, 0, 0, 0.9)", // Bordure orange pour Halloween (#FF5F1F)
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FF00FF)", // Effet de filtre pour le titre de Halloween
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FF00FF)", // Effet de filtre pour l'icône de Halloween

        },
        "la Saint-Valentin": {
            background: "linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(217, 0, 147, 0.7))",
            lottie_StValentin: animationData_Stvalentin_pulse2,
            lottie_StValentin_ButterFly: animationData_Stvalentin_Butterfly,
            icon1: "https://zupimages.net/up/23/15/bnth.png",
            dividerClass: "shapedividers_com-8362",  // Classe pour la Saint-Valentin (#FFFFFF)
            borderStyle: "3px solid rgba(255, 51, 204, 0.9)",  // Bordure pour vignette rose pour la Saint-Valentin
            titleColor: "#FFFFFF", // Couleur du titre pour la Saint-Valentin
            borderTitleHoliday: "5px solid rgba(255, 255, 255, 0.9)", // Bordure noire pour la Saint-Valentin (#FFFFFF)
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour le titre de la Saint-Valentin
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour l'icône de la Saint-Valentin
        },
        "la Pâques": {
            background: "linear-gradient(45deg, rgba(0, 255, 255, 0.8), rgba(255, 51, 204, 0.7))",
            lottie_Pâques: animationData_Easter_ChickEgg,
            icon1: "https://zupimages.net/up/24/36/ukrk.png",
            icon2: "https://zupimages.net/up/24/36/0333.png",
            dividerClass: "shapedividers_com-8362",  // Classe pour Pâques (#FFFFFF)
            borderStyle: "3px solid rgba(217, 0, 147, 0.9)",  // Bordure pour vignette rose pour Pâques
            titleColor: "#FF33CC", // Couleur du titre pour Pâques
            titleColorVignette: "#FF33CC", // Couleur du titre pour Pâques
            borderTitleHoliday: "5px solid rgba(0, 255, 255, 0.9)", // Bordure cyan pour Pâques (#00FFFF)
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FFFFFF)", // Effet de filtre pour le titre de Pâques
            iconFilterEffect: "drop-shadow(0 0 0.25rem #D90093)", // Effet de filtre pour l'icône de Pâques
        },
        "le Nouvel An": {
            background: "linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(32, 2, 17, 0.7))",
            lottie1_NewYear: animationData_NewYear_fireWorks_01,
            lottie2_NewYear: animationData_NewYear_clock,
            icon1: "https://zupimages.net/up/24/36/7m64.png",
            icon2: "https://zupimages.net/up/24/36/p6m6.png",
            dividerClass: "shapedividers_com-8362",  // Classe pour le Nouvel An (#FFFFFF)
            borderStyle: "3px solid rgba(255, 51, 204, 0.9)",  // Bordure pour vignette rose pour le Nouvel An
            titleColor: "#FFFFFF", // Couleur du titre pour le Nouvel An
            borderTitleHoliday: "5px solid rgba(255, 48, 205, 0.9)", // Bordure noire pour le Nouvel An
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour le titre du Nouvel An
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour l'icône du Nouvel An
        },
        "la Saint-Jean-Baptiste": {
            background: "linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(28, 47, 157, 0.7))",
            lottie_StJean: animationData_STJean_Harfang,
            icon1: "https://zupimages.net/up/24/36/9ga6.png",
            dividerClass: "shapedividers_com-8362",  // Classe pour le Nouvel An (#FFFFFF)
            borderStyle: "3px solid rgba(25, 47, 157, 0.9)",  // Bordure pour vignette bleue pour la Saint-Jean-Baptiste
            titleColor: "#FFFFFF", // Couleur du titre pour la Saint-Jean-Baptiste
            titleColorVignette: "#FFFFFF", // Couleur du titre pour la Saint-Jean-Baptiste
            borderTitleHoliday: "5px solid rgba(28, 47, 157, 0.9)", // Bordure bleue pour la Saint-Jean-Baptiste
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FFFFFF)", // Effet de filtre pour le titre de la Saint-Jean-Baptiste
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FFFFFF)", // Effet de filtre pour l'icône de la Saint-Jean-Baptiste
            iconFilterEffect_2: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour l'icône de la Saint-Jean-Baptiste
        },
        "la Fête du Canada": {
            background: "linear-gradient(45deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 99, 71, 0.7) 50%, rgba(255, 0, 0, 0.7) 100%)",
            lottie_Canada: animationData_CanadaDay_01,
            icon1: "https://zupimages.net/up/24/37/6jqx.png",
            dividerClass: "shapedividers_com-8362",  // Classe pour le Nouvel An (#FFFFFF)
            borderStyle: "3px solid rgba(255, 99, 71, 0.9)",  // Bordure pour vignette rouge pour la Fête du Canada
            titleColor: "#FFFFFF", // Couleur du titre pour la Fête du Canada
            titleColorVignette: "#FF6347", // Couleur du titre pour la Fête du Canada 
            borderTitleHoliday: "5px solid rgba(255, 99, 71, 0.9)", // Bordure rouge pour la Fête du Canada
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FFFFFF)", // Effet de filtre pour le titre de la Fête du Canada
            iconFilterEffect: "drop-shadow(0 0 0.20rem #FF6347) drop-shadow(0 0 0.35rem #FFFFFF)", // Effet de filtre pour l'icône de la Fête du Canada
        },
        "la Fête Nationale (États-Unis)": {
            background: "linear-gradient(135deg, rgba(239, 65, 53, 0.9) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(0, 85, 164, 0.8) 100%)",// Dégradé de couleur pour la Fête Nationale des États-Unis
            lottie_USA: animationData_USADay_Eagle_01,
            icon1: "https://zupimages.net/up/24/38/lonc.png",
            icon2: "https://zupimages.net/up/24/38/wure.png",
            dividerClass: "shapedividers_com-8362",  // Classe pour le Nouvel An (#FFFFFF)
            borderStyle: "3px solid rgba(0, 85, 154, 0.9)",  // Bordure pour vignette rouge pour les États-Unis
            titleColor: "#FFFFFF", // Couleur du titre pour la Fête Nationale des États-Unis
            titleColorVignette: "#FF6347", // Couleur du titre pour la Fête Nationale des États-Unis
            borderTitleHoliday: "5px solid rgba(0, 85, 164, 0.75)", // Bordure rouge pour la Fête Nationale des États-Unis
            filterEffectTitleHoliday: "drop-shadow(0 0 0.35rem #00559A)", // Effet de filtre pour le titre de la Fête Nationale des États-Unis
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FF6347)", // Effet de filtre pour l'icône de la Fête Nationale des États-Unis

        },
        "la Fête Nationale (France)": {
            background: "linear-gradient(45deg, rgba(0, 85, 164, 0.9) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(239, 65, 53, 0.9) 100%)",
            lottie_France_01: animationData_FranceDay_01,
            lottie_France_02: animationData_FranceDay_02,
            lottie_France_02a: animationData_FranceDay_02,
            lottie_France_03: animationData_FranceDay_03,
            icon1: "https://zupimages.net/up/24/37/9jl4.png",
            dividerClass: "shapedividers_com-6191",  // Classe pour le Nouvel An (#FFFFFF)
            borderStyle: "3px solid rgba(255, 51, 204, 0.9)",  // Bordure pour vignette bleue pour la Fête Nationale de la France
            titleColor: "#00FFFF", // Couleur du titre pour la Fête Nationale de la France
            titleColorVignette: "#00FFFF", // Couleur du titre pour la Fête Nationale de la France
            borderTitleHoliday: "5px solid rgba(255, 51, 204, 0.9)", // Bordure bleue pour la Fête Nationale de la France
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour le titre de la Fête Nationale de la France
            iconFilterEffectLeft: "drop-shadow(0 0 0.35rem #FF33CC)", // Effet de filtre pour l'icône de la Fête Nationale de la France
            iconFilterEffectRight: "drop-shadow(0 0 0.35rem #00FFFF)", // Effet de filtre pour l'icône de la Fête Nationale de la France
        },
        "le Test-Fête": {  // Utilise le même nom que celui renvoyé par getCurrentHoliday pour tester la logique
            background: "linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(0, 255, 255, 0.7))",
            lottie1: animationData_NewYear_clock,
            lottie2: animationData_NewYear_fireWorks_01,
            icon1: "https://zupimages.net/up/23/15/bnth.png",
            dividerClass: "shapedividers_com-8362",// (#FFFFFF)
        },
        "la fête de l'utilisateur": {
            // background: "linear-gradient(-45deg, rgba(0, 0, 0) 40%, rgba(0, 255, 255) 100%", // Dégradé de base (cyan et blanc) pour la fête de l'utilisateur
            background: "linear-gradient(135deg, rgba(20, 20, 50, 0.9), rgba(75, 0, 130, 0.7), rgba(240, 147, 251, 0.7))", // Dégradé cosmique shadow pour la fête de l'utilisateur;
            // background: "linear-gradient(135deg, rgba(15, 12, 41, 0.8) 0%, rgba(48, 43, 99, 0.8) 50%, rgba(36, 36, 62, 0.9) 100%)", // Dégradé moderne de couleur pour la fête de l'utilisateur;
            // background: "linear-gradient(120deg, rgba(240, 147, 251, 0.8), rgba(117, 58, 136, 0.7), rgba(24, 21, 64, 0.9))", // Dégradé cosmique de couleur pour la fête de l'utilisateur;
            lottie_User_ZodiacWheel: animationData_UserHoliday_ZodiacWheel,
            icon1: zodiacIcons[userZodiac] || "https://zupimages.net/up/23/15/bnth.png",  // Icône dynamique basée sur le signe du zodiaque
            dividerClass: "shapedividers_com-8362",  // Classe identique à la Saint-Valentin
            borderStyle: "3px solid rgba(255, 51, 204, 0.9)",  // Bordure pour vignette rose
            titleColor: "#FFFFFF", // Couleur du titre pour la fête de l'utilisateur
            borderTitleHoliday: "5px solid rgba(255, 255, 255, 0.9)", // Bordure blanche
            filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour le titre
            iconFilterEffect: "drop-shadow(0 0 0.25rem #FF33CC)", // Effet de filtre pour l'icône
        },
    };

    // Définir une valeur par défaut pour les visuels
    const defaultVisual = {
        background: "linear-gradient(-45deg, rgba(255, 255, 255, 0.8), rgba(255, 51, 204, 0.7))", // Dégradé de couleur par défaut #FFFFFF et #FF33CC
        lottie_default: animationData_Default_passion_Cinema, // Animation par défaut
        icon1: "https://zupimages.net/up/24/36/qtpo.png",  // Icone par défaut
        dividerClass: "shapedividers_com-981", // Classe par défaut pour le diviseur #200211
        borderStyle: "3px solid rgba(32, 2, 17, 0.9)",  // Bordure par défaut pour vignette #200211
        titleColor: "#FFFFFF", // Couleur du titre par défaut
        titleColorVignette: "#D90093", // Couleur du titre pour la vignette par défaut
        borderTitleHoliday: "5px solid rgba(32, 2, 17, 0.9)", // Bordure par défaut pour le titre #FF33CC
        filterEffectTitleHoliday: "drop-shadow(0 0 0.25rem #200211)", // Effet de filtre par défaut pour le titre
        iconFilterEffect: "drop-shadow(0 0 0.25rem #00FFFF)", // Effet de filtre par défaut pour l'icôn
    };

    useEffect(() => {
        // Vérifier si l'utilisateur est connecté et dans sa période zodiacale
        const isUserInZodiacPeriod = isLoggedIn && isUserZodiacPeriod;
        setIsUserZodiacPeriodActive(isUserInZodiacPeriod);
    }, [isLoggedIn, isUserZodiacPeriod]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!userId || !isLoggedIn) return;

            try {
                const userRef = doc(db, 'users', userId);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setUserData(data);
                    // Tu récupères bien les autres informations ici comme zodiacSign
                } else {
                    console.log('Aucune donnée utilisateur trouvée pour cet ID');
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
            }
        };

        fetchUserData();
    }, [userId, isLoggedIn]);

    const getCurrentHoliday = (userLocation) => {
        const today = new Date();
        const currentYear = today.getFullYear();

        const holidays = [
            // { name: "le Noël", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19},
            { name: "le Noël", startMonth: 12, startDay: 23, endMonth: 12, endDay: 27 },
            // { name: "l'Halloween", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19 },
            { name: "l'Halloween", startMonth: 10, startDay: 29, endMonth: 11, endDay: 2 },
            // { name: "la Pâques", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19 },
            { name: "la Pâques", startMonth: 4, startDay: 3, endMonth: 4, endDay: 5 },
            // { name: "la Saint-Valentin", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19},
            { name: "la Saint-Valentin", startMonth: 2, startDay: 12, endMonth: 2, endDay: 16 },
            // { name: "le Nouvel An", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19},
            { name: "le Nouvel An", startMonth: 12, startDay: 31, endMonth: 1, endDay: 2 },
            // { name: "la Fête du Canada", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19, location: "Canada" },
            { name: "la Fête du Canada", startMonth: 6, startDay: 29, endMonth: 7, endDay: 3, location: "Canada" },
            // { name: "la Fête Nationale (France)", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19, location: "France" },
            { name: "la Fête Nationale (France)", startMonth: 7, startDay: 12, endMonth: 7, endDay: 16, location: "France" },
            // { name: "la Fête Nationale (États-Unis)", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19, location: "États-Unis" },
            { name: "la Fête Nationale (États-Unis)", startMonth: 7, startDay: 4, endMonth: 7, endDay: 6, location: "États-Unis" },
            // Je dois présicer que la fête de la Saint-Jean-Baptiste est une fête nationale au Canada, mais elle est célébrée uniquement au Québec          
            // { name: "la Saint-Jean-Baptiste", startMonth: 11, startDay: 15, endMonth: 11, endDay: 19, location: "Canada" },
            { name: "la Saint-Jean-Baptiste", startMonth: 6, startDay: 22, endMonth: 6, endDay: 26, location: "Canada" },
            //J'invente temporairement une fête qui correspond à oujourd'hui juste pour tester si la logique prend bien effet..
            // { name: "le Test-Fête", startMonth: 9, startDay: 25, endMonth: 9, endDay: 30},
            // Nouvelle entrée pour l'anniversaire de l'utilisateur, couvrant tout le mois
            { name: "la fête de l'utilisateur", startMonth: userData.birthMonth, startDay: 1, endMonth: userData.birthMonth, endDay: 31 },
        ];

        const currentHoliday = holidays.find(holiday => {
            let startDate = new Date(currentYear, holiday.startMonth - 1, holiday.startDay);
            let endDate = new Date(currentYear, holiday.endMonth - 1, holiday.endDay);

            // Gérer les fêtes qui s'étendent sur la nouvelle année
            if (endDate < startDate) {
                endDate.setFullYear(endDate.getFullYear() + 1);
            }

            // Vérifier si aujourd'hui est entre startDate et endDate
            return today >= startDate && today <= endDate;
        });

        return currentHoliday ? currentHoliday.name : "la passion du cinéma";
    };


    const fetchFilmsForHoliday = async (holiday) => {
        let genreIdsArray = [];
        let additionalFilters = '';
        let additionalFiltersQuebec = '';

        switch (holiday) {
            case "le Noël":
                genreIdsArray = "35,14,10751"; // Comédie, Fantastique, Familial
                break;
            case "l'Halloween":
                genreIdsArray = "27,53"; // Horreur, Thriller
                break;
            case "la Pâques":
                genreIdsArray = "16,10751"; // Animation, Familial
                break;
            case "la Saint-Valentin":
                genreIdsArray = "10749"; // Romance
                break;
            case "le Nouvel An":
                genreIdsArray = "35,18,12"; // Comédie, Drame, Aventure
                break;
            case "la Fête du Canada":
                genreIdsArray = ['']; // Aventure, Drame, Histoire
                additionalFilters = "&with_origin_country=CA";
                break;
            case "la Fête Nationale (États-Unis)":
                genreIdsArray = ['']; // Action, Aventure, Histoire, Western
                additionalFilters = "&with_origin_country=US"; // Filtres pour films américains
                break;
            case "la Fête Nationale (France)":
                genreIdsArray = ['']; // Histoire, Drame, Action
                additionalFilters = "&with_origin_country=FR";// Filtres pour films français
                break;
            case "la Saint-Jean-Baptiste":
                genreIdsArray = ['']; // Pas de genre spécifique pour la Saint-Jean-Baptiste
                additionalFilters = "&with_origin_country=CA"; // Filtres pour films québécois, région canadiens
                additionalFiltersQuebec = "&with_original_language=fr"; // Filtres pour films québécois, filtre langue française
                break;
            case "le Test-Fête":
                genreIdsArray = "53,12,35"; // Thriller, Aventure, Comédie
                break;
            default:
                genreIdsArray = default_genre_ids; // Utilise le genre par défaut si aucun genre n'est trouvé
                break;
        }

        const currentVisual = holidayVisuals[currentHoliday] || defaultVisual;

        const today = new Date().toISOString().slice(0, 10);
        const lastTenYears = new Date().getFullYear() - 10;

        let allResults = [];


        // Faire une requête pour chaque genre individuellement
        for (const genreId of genreIdsArray) {
            const url = `https://api.themoviedb.org/3/discover/movie?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr${additionalFilters + additionalFiltersQuebec}&with_genres=${genreIdsArray}&release_date.lte=${today}&release_date.gte=${lastTenYears}-01-01&sort_by=popularity.desc&include_adult=false&page=1&include_video=false`;
            // const url = `https://api.themoviedb.org/3/discover/movie?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr${additionalFilters}&sort_by=popularity.desc&include_adult=false${genreIdsArray ? `&with_genres=${genreIdsArray}` : ''}&release_date.lte=${today}&release_date.gte=${lastTenYears}-01-01&page=1&include_video=false`;

            try {
                const response = await fetch(url);
                const data = await response.json();
                allResults = [...allResults, ...data.results]; // Ajouter les résultats à la liste totale
            } catch (error) {
                console.error(`Erreur avec le genre ${genreIdsArray}:`, error);
            }
        }

        // Supprimer les doublons si un film appartient à plusieurs genres
        const uniqueResults = Array.from(new Set(allResults.map(film => film.id)))
            .map(id => allResults.find(film => film.id === id));

        return uniqueResults.slice(0, 8); // Limiter à 8 résultats
    };

    const fetchFilmsForUserHoliday = async () => {
        let genreIds = userData.favoriteGenres?.join(',') || default_genre_ids; // Utilise les genres favoris ou des genres par défaut

        const url = `https://api.themoviedb.org/3/discover/movie?api_key=API_KEY&with_genres=${genreIds}&language=fr`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            setUserHolidayFilms(data.results);
        } catch (error) {
            console.error("Erreur lors de la récupération des films pour la fête de l'utilisateur:", error);
        }
    };



    useEffect(() => {
        const holiday = getCurrentHoliday(userData.location || '');
        setCurrentHoliday(holiday);
    }, [userData]);

    useEffect(() => {
        const visual = holidayVisuals[currentHoliday] || defaultVisual;
        console.log("Current Holiday:", currentHoliday);
        console.log("Visual applied:", visual);
        setCurrentVisual(visual);
    }, [currentHoliday]);


    // Fonction qui vérifie si une fête majeure est en cours avant de prioriser la fête de l'utilisateur
    const getCurrentHolidayWithPriority = () => {
        const userLocation = userData.location || '';
        const holiday = getCurrentHoliday(userLocation);

        // Priorité aux fêtes majeures
        if (holiday === "l'Halloween" || holiday === "le Noël" || holiday === "la Pâques" || holiday === "la Saint-Valentin" ||
            holiday === "le Nouvel An" || holiday === "la Fête du Canada" || holiday === "la Fête Nationale (États-Unis)" ||
            holiday === "la Fête Nationale (France)" || holiday === "la Saint-Jean-Baptiste") {
            return holiday; // Si une fête majeure est en cours, elle est prioritaire
        }

        // Si aucune fête majeure, on regarde la fête de l'utilisateur
        const userHoliday = getUserHoliday();
        if (userHoliday) {
            return userHoliday; // Si la fête de l'utilisateur est en cours, elle est utilisée
        }

        // Retour par défaut si aucune fête n'est active
        return "la passion du cinéma";
    };

    const getUserHoliday = () => {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Les mois sont 0-indexés en JavaScript
        const currentDay = today.getDate();

        const userZodiac = userData.zodiacSign;

        // Vérifier si la date actuelle tombe dans la période du signe de l'utilisateur
        if (userZodiac && zodiacPeriods[userZodiac]) {
            const { startMonth, startDay, endMonth, endDay } = zodiacPeriods[userZodiac];

            const isUserHoliday = (currentMonth > startMonth || (currentMonth === startMonth && currentDay >= startDay)) &&
                (currentMonth < endMonth || (currentMonth === endMonth && currentDay <= endDay));

            if (isUserHoliday) {
                return "la fête de l'utilisateur";
            }
        }

        return null; // Pas de fête de l'utilisateur pour cette date
    };

    useEffect(() => {
        const holiday = getCurrentHolidayWithPriority();
        setCurrentHoliday(holiday);

        const fetchFilms = async () => {
            if (holiday === "la fête de l'utilisateur") {
                await fetchFilmsForUserHoliday();
            } else {
                const films = await fetchFilmsForHoliday(holiday);
                setHolidayFilms(films);
            }
        };

        fetchFilms();
    }, [userData]);



    useEffect(() => {
        const visual = holidayVisuals[currentHoliday] || defaultVisual;
        console.log("Current Holiday:", currentHoliday);
        console.log("Visual applied:", visual);
        setCurrentVisual(visual);
    }, [currentHoliday]);



    useEffect(() => {
        const fetchTendancesFilms = async () => {
            const today = new Date().toISOString().slice(0, 10);
            const sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
            const formattedSixMonthsAgo = sixMonthsAgo.toISOString().slice(0, 10);

            const url = `https://api.themoviedb.org/3/discover/movie?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr&region=FR&sort_by=vote_count.desc&release_date.lte=${today}&release_date.gte=${formattedSixMonthsAgo}&vote_count.gte=100`;

            try {
                const response = await fetch(url);
                const data = await response.json();
                setTendancesFilms(data.results.slice(0, 8));
            } catch (error) {
                console.log(error);
            }
        };

        fetchTendancesFilms();
    }, []);

    useEffect(() => {
        const fetchMeilleursChoixFilms = async () => {
            const lastYear = new Date().getFullYear() - 1;
            const url = `https://api.themoviedb.org/3/discover/movie?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr&region=FR&sort_by=popularity.desc&vote_count.gte=100&include_adult=false&year=${lastYear}`;

            try {
                const response = await fetch(url);
                const data = await response.json();
                setMeilleursChoixFilms(data.results.slice(0, 8));
            } catch (error) {
                console.error(error);
            }
        };

        fetchMeilleursChoixFilms();
    }, []);


    useLayoutEffect(() => {
        const elementsA = document.querySelectorAll('.section-1, .section-3_default');
        const elementsB = document.querySelectorAll('.section-2');

        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(entry.target.classList.contains('section-1') || entry.target.classList.contains('section-3_default') ? 'animate-translateSectionA' : 'animate-translateSectionB');
                } else {
                    entry.target.classList.remove('animate-translateSectionA', 'animate-translateSectionB');
                }
            });
        };

        const observerOptions = { threshold: 0.5 };
        const observer = new IntersectionObserver(observerCallback, observerOptions);

        elementsA.forEach(el => observer.observe(el));
        elementsB.forEach(el => observer.observe(el));

        return () => observer.disconnect();
    }, [triggerAnimation]);

    return (

        <div className="sections-accueil">
            <div className="section-1 row shapedividers_com-6191">
                <div className="col-12 sectionCol-1">
                    <div className="content-container">
                        <div className="title-container">
                            <div className="title-border">
                                <h2 className="title-text no-border" id='titleMoment'>TENDANCES DU MOMENT DE {isLoggedIn ? userData.username : "TOUS"}</h2>
                            </div>
                        </div>
                        <div className="movie-grid">
                            {tendancesFilms.map((film) => (
                                <Movie
                                    key={film.id}
                                    movie={film}
                                    onClickMovie={() => history(`/ficheFilm/${film.id}`)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-2 row shapedividers_com-7773">
                <div className="col-12 sectionCol-2">
                    <div className="title-container">
                        <div className="title-border">
                            <h2 className="title-text no-border" id='titleChoix' style={{ zIndex: '10' }}>MEILLEURS CHOIX POUR {isLoggedIn ? userData.username : "TOUS"}</h2>
                        </div>
                    </div>
                    <div className="movie-grid">
                        {meilleursChoixFilms.map((film) => (
                            <Movie
                                key={film.id}
                                movie={film}
                                onClickMovie={() => history(`/ficheFilm/${film.id}`)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className={`section-3_default row ${currentVisual.dividerClass}`} style={{ background: currentVisual.background }}>
                <div className="col-12 sectionCol-3">
                    <div className="section3content">
                        <div className="section-3-title">
                            <div className="title-container"
                                style={{
                                    color: holidayVisuals[currentHoliday]?.borderTitleHoliday,
                                    transform: isHalloween ? "translateY(-2vh)" : "none",
                                }}
                            >
                                <Lottie
                                    animationData={currentVisual.lottie_Halloween_2} // Araignée
                                    loop={true}
                                    autoplay={true}
                                    className={clsx('lottie-halloween-spider', {
                                        'isHalloween': isHalloween,
                                    })}
                                    style={{
                                        transform: "scale(1.55)",
                                        width: "10vw",
                                        height: "3.5.5vh",
                                        top: "7.5vh",
                                        left: "10.25vw",
                                        zIndex: 5001,
                                        pointerEvents: "none",
                                        position: "absolute",
                                        display: isHalloween ? "block" : "none"
                                    }}
                                />
                                <div
                                    className={clsx('title-border_B', {
                                        'holiday-title-color': holidayVisuals[currentHoliday]?.titleColor,
                                        'default-title-color': !holidayVisuals[currentHoliday]?.titleColor,
                                    })}
                                    id="holidayBorder"
                                    style={{
                                        border: holidayVisuals[currentHoliday]?.borderTitleHoliday || defaultVisual.borderTitleHoliday,
                                        filter: holidayVisuals[currentHoliday]?.filterEffectTitleHoliday || defaultVisual.filterEffectTitleHoliday,
                                    }}
                                >
                                    <h2 className="title-text no-border" id="titleValentin" style={{
                                        color: holidayVisuals[currentHoliday]?.titleColor,
                                    }}>
                                        {currentHoliday === "la fête de l'utilisateur"
                                            ? `Parce que, `
                                            : `POUR ${currentHoliday.toUpperCase()} DE `}
                                        <span style={{ color: "#00FFFF" }}>
                                            {isLoggedIn ? userData.username : "TOUS"}
                                        </span>
                                        {currentHoliday === "la fête de l'utilisateur"
                                            ? `, nous sommes en période ${zodiacTranslations[userData.zodiacSign] || userData.zodiacSign}, célébrons votre anniversaire !`
                                            : ""}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={clsx('col-12 col-md-12 zoddiacAndLottieContainer', {
                        'user-zodiac-period': isUserZodiacPeriodActive,
                    })}
                    style={{ zIndex: 5000 }}
                >
                    <div
                        className={clsx(
                            'iconWithLottie d-flex justify-content-between align-items-center',
                            holidayClass,
                            {
                                'user-zodiac-period': isUserZodiacPeriodActive,
                            }
                        )}
                        style={{
                            padding: isUserZodiacPeriod ? "10vh 0" : "0",
                            // border: "3px solid yellow",
                            // margin: "3.5rem 0",
                        }}
                    >
                        {/* <!-- Icon à gauche --> */}
                        <img
                            src={currentVisual.icon1}
                            alt="iconHolidayLeft"
                            className={clsx('_iconHoliday', 'iconMarkLeft', {
                                isEasterStyle: isEaster,
                                isValentineStyle: isValentine,
                                isFranceDayStyle: isFranceDay,
                                'hovered-style': isHovered,
                                'independance-day-opacity': isIndependanceDay && !isHovered,
                                'iconMarkLeft-hovered': isHovered, // Ajouter une classe pour l'état survolé
                                'user-zodiac-period': isUserZodiacPeriodActive,
                            })}
                            style={{
                                background: currentVisual.icon1 === defaultVisual.icon1
                                    ? (isHovered ? 'linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(0, 255, 255, 0.7))' : '#9795C6')
                                    : 'none',
                                borderRadius: currentVisual.icon1 === defaultVisual.icon1 ? '50%' : 'none',
                                padding: currentVisual.icon1 === defaultVisual.icon1 ? '10px' : '0',
                                marginLeft: "1vw",
                                zIndex: 10,
                                overflow: 'visible',
                                opacity: isHovered ? 0.75 : (isIndependanceDay ? 0.35 : 1),
                                transition: "transform 0.3s ease-in-out", // Garder la transition ici ou la déplacer dans le CSS
                                filter: currentHoliday === "la Saint-Jean-Baptiste"
                                    ? (isHovered ? currentVisual.iconFilterEffect_2 : currentVisual.iconFilterEffect)
                                    : currentHoliday === "la Fête Nationale (France)"
                                        ? (isHovered ? currentVisual.iconFilterEffectRight : "none")
                                        : (isHovered ? currentVisual.iconFilterEffect : "none")
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        />
                        {/* <!-- Lotties centrées --> */}
                        <div
                            id="id_iconHoliday"
                            className={clsx('lottieCenter', holidayClass, {
                                halloweenStyle: isHalloween,
                                isStJeanStyle: isStJean,
                                isEasterStyle: isEaster,
                                isValentineStyle: isValentine,
                                isFranceDayStyle: isFranceDay,
                                isIndependanceDayStyle: isIndependanceDay,
                                isUserZodiacPeriod: isUserZodiacPeriod,
                                'user-zodiac-period': isUserZodiacPeriodActive, // Ajouter une classe pour la période du zodiaque de l'utilisateur
                            })}
                        >
                            <img
                                src={zodiacBackgrounds[userZodiac]}
                                alt={`Background for ${userZodiac}`}
                                className={clsx('zodiacImgMobile', {
                                    isUserZodiacPeriod: isUserZodiacPeriod,
                                })}
                                style={{
                                    display: isMobileSmall && isUserZodiacPeriod ? 'block' : 'none',
                                    zIndex: 1,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    transform: 'scale(0.85)', // Ajuste le scale si besoin
                                }}
                            />
                            {/* mode mobile */}
                            <ZodiacContainer>
                                <img
                                    src={zodiacLogoText[userZodiac]}
                                    alt={`Logo Text for ${userZodiac}`}
                                    className={clsx('zodiac-logo-text', {
                                        isUserZodiacPeriod: isUserZodiacPeriod,
                                        'user-zodiac-period': isUserZodiacPeriodActive, // Ajouter une classe pour la période du zodiaque de l'utilisateur
                                        isMobileSmall: isMobileSmall,
                                    })}
                                    style={{ width: '22.5rem', height: 'auto', marginTop: '-2.5rem', marginRight: '-2vw', transform: 'rotate(3.5deg)', zIndex: 10000, display: isMobileSmall && isUserZodiacPeriod ? 'block' : 'none' }}
                                />
                            </ZodiacContainer>
                            {/* Ici tu affiches la roue Zodiac */}
                            {isUserZodiacPeriod && (
                                <LottieCenter
                                    isUserZodiacPeriod={isUserZodiacPeriod}
                                    zodiacBackground={zodiacBackgrounds[userZodiac]}
                                    className={clsx('dynamic-border', {
                                        isUserZodiacPeriod: isUserZodiacPeriod,
                                        'user-zodiac-period': isUserZodiacPeriodActive, // Ajouter une classe pour la période du zodiaque de l'utilisateur
                                    })}
                                    style={{
                                        display: isMobileSmall ? 'none' : 'block',
                                    }}
                                >
                                    {/* mode Pc */}
                                    <ZodiacContainer>
                                        <img
                                            src={zodiacLogoText[userZodiac]}
                                            alt={`Logo Text for ${userZodiac}`}
                                            className={clsx('zodiac-logo-text', {
                                                isUserZodiacPeriod: isUserZodiacPeriod,
                                                'user-zodiac-period': isUserZodiacPeriodActive, // Ajouter une classe pour la période du zodiaque de l'utilisateur
                                                isMobileSmall: isMobileSmall,
                                            })}
                                            style={{ width: '22.5rem', height: 'auto', marginTop: '-2.5rem', marginRight: '-2vw', transform: 'rotate(3.5deg)', zIndex: 10000, display: isMobileSmall && isUserZodiacPeriod ? 'none' : 'block' }}
                                        />
                                    </ZodiacContainer>
                                </LottieCenter>
                            )}
                            {/* <LottieCenter isUserZodiacPeriod={isUserZodiacPeriod} zodiacBackground={zodiacBackgrounds[userZodiac]}>
                                {isUserZodiacPeriod && (
                                    <ZodiacContainer>
                                        <Circle
                                            color="#ff00ff"  // Couleur du cercle (peut être ajustée)
                                            size={['300px', '300px']}  // Taille ajustée
                                            zIndex={5}  // Plus élevé pour s'assurer qu'il est visible
                                            position="absolute"
                                            top="50px"  // Ajustement de la position pour qu'il soit visible
                                            left="50px"
                                            animation={{
                                                type: 'rotate',  // Animation de rotation
                                                duration: 10,  // Durée en secondes
                                                timingFunction: 'linear',  // Animation fluide
                                            }}
                                        />
                                        <img
                                            src={zodiacLogoText[userZodiac]}
                                            alt={`Logo Text for ${userZodiac}`}
                                            style={{
                                                width: '22.5rem',
                                                height: 'auto',
                                                marginTop: '-2.5rem',
                                                marginRight: '-2vw',
                                                transform: 'rotate(3.5deg)',
                                            }}  // Style pour le logo texte
                                        />
                                    </ZodiacContainer>
                                )}
                            </LottieCenter> */}
                            {/* <LottieCenter
                                isUserZodiacPeriod={isUserZodiacPeriod}
                                zodiacBackground={zodiacBackgrounds[userZodiac]}
                            >
                            </LottieCenter> */}
                            {isIndependanceDay && (
                                <div
                                >
                                    <MyParticlesComponent />
                                </div>
                            )}
                            <img
                                src={currentVisual.icon2}
                                alt="iconHolidayCimetierre"
                                className={clsx('_iconHoliday', {
                                    'icon-cimetery': isHalloween_Cimeterry,
                                })}
                                style={{
                                    transform: "scale(4.25)",
                                    zIndex: 40,
                                    position: "relative",
                                    top: "32.5vh",
                                    left: "-1.75vw",
                                    pointerEvents: "none",
                                    display: isHalloween_Cimeterry ? "block" : "none",
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie1}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.85)"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie2}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.85)"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_noel_1}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(2.85)",
                                    marginTop: "8.5vh",
                                    zIndex: 20,
                                    position: "relative",
                                    top: "10vh"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_noel_2}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.55)",
                                    marginBottom: "-12vh",
                                    zIndex: 10,
                                    position: "relative"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_default}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.5)",
                                    zIndex: 10,
                                    top: "22.5vh",
                                    // top: "-24.5vh",
                                    position: "relative"
                                }}
                                className={clsx('lottieCenterMobile', holidayClass)}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_StJean}
                                loop={true}
                                autoplay={true}
                                className={clsx({
                                    'lottie-stjean': currentHoliday === "la Saint-Jean-Baptiste",
                                })}
                                style={{
                                    transform: "scale(0.25)",
                                    zIndex: 10,
                                    top: "-25.5vh",
                                    position: "relative",
                                    marginTop: "-7.5vh"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_Pâques}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.75)",
                                    zIndex: 10,
                                    top: "26.5vh",
                                    position: "relative"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_StValentin}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(1.25)",
                                    zIndex: 10,
                                    top: "37.5vh",
                                    position: "relative"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_StValentin_ButterFly}
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(1.25)",
                                    zIndex: 5,
                                    top: "-47.5vh",
                                    position: "relative"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie2_NewYear} // horloge
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.4)",
                                    zIndex: 20,
                                    top: "12.5vh",
                                    position: "relative"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie1_NewYear} // feux d'artifice
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.55)",
                                    zIndex: 10,
                                    top: "-55vh",
                                    position: "relative"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_Canada} // Cosmique Castor
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.55)",
                                    top: "12.5vh",
                                    position: "relative",
                                    zIndex: 10
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_Halloween} // Chauve-souris
                                loop={true}
                                autoplay={true}
                                className={clsx('lottie-halloween-bat', {
                                    'isHalloween': isHalloween,
                                })}
                                style={{
                                    transform: "scale(0.85)",
                                    top: "12.5vh",
                                    zIndex: 10,
                                    pointerEvents: "none",
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_France_01} // Tour Eiffel
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.5)",
                                    top: "19.5vh",
                                    zIndex: 10,
                                    pointerEvents: "none",
                                    position: "relative",
                                    filter: "drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_France_02} // Drapeau droit
                                loop={true}
                                autoplay={true}
                                className='lottie-france-flagRight'
                                style={{
                                    transform: "scale(0.22) rotate(45deg)",
                                    top: "-22vh",
                                    left: "9.25vw",
                                    zIndex: -55,
                                    pointerEvents: "none",
                                    position: "relative",
                                    filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8))",
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_France_02a} // Drapeau gauche
                                loop={true}
                                autoplay={true}
                                className='lottie-france-flagLeft'
                                style={{
                                    transform: "scale(0.22) rotate(-45deg) scaleX(-1)",
                                    top: "-64.5vh",
                                    left: "-9.25vw",
                                    zIndex: -55,
                                    pointerEvents: "none",
                                    position: "relative",
                                    filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8))"
                                }}
                            />
                            <Lottie
                                animationData={currentVisual.lottie_France_03} // Carte
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.3875)",
                                    top: "-123.55vh",
                                    zIndex: -50,
                                    pointerEvents: "none",
                                    position: "relative",
                                    border: "3em solid #00FFFF",
                                    borderRadius: "25%",
                                    filter: "drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.6))"
                                }}
                            />
                            {/* <Lottie
                                animationData={currentVisual.lottie_User_ZodiacWheel} // Roue des signes du zodiaque
                                loop={true}
                                autoplay={true}
                                style={{
                                    transform: "scale(0.75)",
                                    // top: "11.5vh",
                                    position: "relative",
                                    zIndex: 10

                                }}
                            /> */}
                            <div
                                className={clsx('zodiac-wheel-container', {
                                    isMobileSmall: isMobileSmall,
                                    isUserZodiacPeriod: isUserZodiacPeriod,
                                    'user-zodiac-period': isUserZodiacPeriodActive,
                                })}
                                style={{
                                    display: isUserZodiacPeriod ? 'block' : 'none',
                                }}
                            >
                                <img
                                    src="https://zupimages.net/up/24/39/2xjv.png"
                                    alt="Zodiac Wheel"
                                    className="zodiac-wheel"
                                    style={{
                                        opacity: isUserZodiacPeriod ? 0.45 : 0,
                                        transform: 'scale(1.2)', // Agrandir légèrement la roue
                                        transition: 'transform 0.5s ease-in-out',
                                    }}
                                />
                            </div>
                        </div>
                        {/* <!-- Icon à droite --> */}
                        <img
                            src={currentVisual.icon1}
                            alt="iconHolidayRight"
                            className={clsx('_iconHoliday', 'iconMarkRight', {
                                'hovered-style': isHoveredRight,
                                'independance-day-opacity': isIndependanceDay && !isHoveredRight,
                                'iconMarkRight-hovered': isHoveredRight, // Ajouter une classe pour l'état survolé
                                'user-zodiac-period': isUserZodiacPeriodActive,
                            })}
                            style={{
                                background: currentVisual.icon1 === defaultVisual.icon1
                                    ? (isHoveredRight ? 'linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(0, 255, 255, 0.7))' : '#9795C6')
                                    : 'none',
                                borderRadius: currentVisual.icon1 === defaultVisual.icon1 ? '50%' : 'none',
                                padding: currentVisual.icon1 === defaultVisual.icon1 ? '10px' : '0',
                                marginRight: "1vw",
                                zIndex: 10,
                                overflow: 'visible',
                                opacity: isHoveredRight ? 0.75 : (isIndependanceDay ? 0.35 : 1),
                                transition: "transform 0.3s ease-in-out", // Garder la transition ici ou la déplacer dans le CSS
                                filter: currentHoliday === "la Saint-Jean-Baptiste"
                                    ? (isHoveredRight ? currentVisual.iconFilterEffect_2 : currentVisual.iconFilterEffect)
                                    : currentHoliday === "la Fête Nationale (France)"
                                        ? (isHoveredRight ? currentVisual.iconFilterEffectRight : "none")
                                        : (isHoveredRight ? currentVisual.iconFilterEffect : "none")
                            }}
                            onMouseEnter={() => setIsHoveredRight(true)}
                            onMouseLeave={() => setIsHoveredRight(false)}
                        />
                    </div>
                </div>
                <div className={clsx('movie-grid mv3', {
                    'user-zodiac-period': isUserZodiacPeriodActive,
                })}>
                    {holidayFilms.length > 0 ? (
                        holidayFilms.map((film) => (
                            <Movie
                                key={film.id}
                                movie={film}
                                additionalClass={clsx('movie-card', {
                                    'hovered-shadow_movie_grill': isHovered,
                                })}
                                titleColor={holidayVisuals[currentHoliday]?.titleColorVignette}
                                onClickMovie={() => history(`/ficheFilm/${film.id}`)}
                                borderStyle={holidayVisuals[currentHoliday]?.borderStyle}
                            />
                        ))
                    ) : (
                        <p>Aucun film trouvé pour cette fête.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SectionsAccueil;

