import React, { useState, useEffect, useContext, createContext } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import CircularNicknameSvg from '../components/CircularNicknameSvg';
import MobileCircularNicknameSvg from '../components/MobileCircularNicknameSvg';
import { doc, updateDoc, getDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from '../UserContext';
import { defaultBannerUrl, defaultAvatarUrl, defaultUsername } from '../constants';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import styles from '../styles/components/profilMember.scss'; // Assurez-vous que ce chemin est correct
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import { auth, db, storage } from '../firebasedeconfig';
import PreviewMyFavoritesMovies from "../components/PreviewMyFavoritesMovies";
import MyFavoritesActors from "../components/MyFavoritesActors";
import My3FavoriteCategories from "../components/My3FavoriteCategories";
import { mapZodiacValueToImageUrl, mapGenderValueToDisplay, mapOccupationValueToDisplay } from '../components/valueToDisplayMappings.js';
import { Country, State } from 'country-state-city';
import { RefreshContext } from '../components/RefreshContext';
import EditTransition from '../components/EditTransition';
import UseWindowSize from '../components/UseWindowSize';
import { Helmet } from "react-helmet"; // Ajout pour les balises SEO
// import OnlineUsersProvider  from '../OnlineUsersContext';


const ProfilMember = () => {

    const urlParams = new URLSearchParams(window.location.search);
    // const showAnimation = urlParams.get("showAnimation") === "true";
    // const [setShowAnimation] = useState(false);
    const showAnimationFromUrl = urlParams.get("showAnimation") === "true";
    const [showAnimation, setShowAnimation] = useState(showAnimationFromUrl);
    const [forceRender, setForceRender] = useState(0);
    const { width, height } = UseWindowSize();
    const isMobile = width < 768; // Détermine si c'est un affichage mobile


    let videoOpts;

    if (width <= 320) { // iPhone 5/SE 1ère génération/*  */
        videoOpts = {
            height: '...',
            width: '...',
            // autres options spécifiques
        };
    } else if (width > 320 && width <= 370) {
        videoOpts = {
            height: '107',
            width: '258',
            // autres options spécifiques
        };
    } else if (width > 370 && width <= 379) {
        videoOpts = {
            height: '115px',
            width: '263px',
            // autres options spécifiques
        };
    } else if (width > 370 && width <= 379 && height >= 812) {
        videoOpts = {
            height: '118px',
            width: '269px',
            // autres options spécifiques
        };
    } else if (width > 379 && width <= 411) {
        videoOpts = {
            height: '125px',
            width: '293px',
            // autres options spécifiques
        };
    } else if (width > 411 && width <= 415) {
        videoOpts = {
            height: '134.5px',
            width: '269.25px',
            // autres options spécifiques
        };
    } else { // résolutions plus grandes
        videoOpts = {
            height: '308.75px',
            width: '765px',
            // border: '2px solid #30df8e',
            // options par défaut
        };
    }


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);


    useEffect(() => {
        if (showAnimation) {
            const timer = setTimeout(() => {
                const newUrl = window.location.href.replace("?showAnimation=true", "");
                window.history.replaceState({}, "", newUrl);
            }, 6000);

            // Fonction de nettoyage pour effacer le timeout
            return () => clearTimeout(timer);
        }
        // Ajoutez ici showAnimation si vous voulez que le timeout soit réinitialisé lorsque showAnimation change
    }, [showAnimation]);


    const history = useNavigate();
    // const { db } = useParams();

    const { userId } = useContext(UserContext);
    // console.log("Test avatar 09. userId est :", userId);
    const { refreshNeeded, setRefreshNeeded } = useContext(RefreshContext);
    const db = getFirestore();
    // console.log("Test avatar 11. db est :", db);

    const { username, setUsername, email, setEmail, banner, setBanner, avatar, setAvatar, isLoggedIn, userIsLoggedIn } = useContext(UserContext);
    const [userData, setUserData] = useState({});
    const [videoKey, setVideoKey] = useState("");
    const [favoritePreviews, setFavoritePreviews] = useState([]);
    const [favoriteActors, setFavoriteActors] = useState([]);
    const [favoriteCategories, setFavoriteCategories] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const zodiacImageUrl = mapZodiacValueToImageUrl(userData.zodiacSign);
    const countryName = Country.getCountryByCode(userData.country)?.name || userData.country;
    // console.log("Test102 : " + JSON.stringify(userData));
    const allStates = State.getStatesOfCountry(userData.country);
    const stateObj = allStates.find(s => s.isoCode === userData.state);
    const [zIndex, setZIndex] = useState(40000);
    const [isLoading, setIsLoading] = useState(false);
    const [imageChangeCounter, setImageChangeCounter] = useState(0);



    // console.log('stateObj:', stateObj);
    // console.log('stateObj ? stateObj.name : "Non disponible":', stateObj ? stateObj ? stateObj.name : "Non disponible" : 'stateObj is undefined');
    // console.log('userData.state:', userData.state);

    const stateName = stateObj ? stateObj.name : "Non disponible";
    // const [showAnimation, setShowAnimation] = useState(false);
    const { showAnimParam } = useParams();
    const [animationTriggered, setAnimationTriggered] = useState(false);




    const handleButtonEditionProfile = () => {
        history(`/EditProfilPage/${userId}`);
    };

    useEffect(() => {
        if (showAnimParam === 'true') {
            setShowAnimation(true);
        }
    }, [showAnimParam]);

    useEffect(() => {
        const fetchUserData = async () => {
            // console.log("Test 07 Avatar et Banner. Current userId: ", userId);
            if (!userId || !isLoggedIn) return;
            // Réinitialiser l'état

            try {
                const userRef = doc(db, 'users', userId);
                // console.log("Test avatar 10. db est :", userRef);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setUserData(data);
                    console.log("Signe zodiacal pour userData.zodiacSign:", data.zodiacSign); // <-- Ajoutez cette ligne ici
                    setBanner(data.banner || defaultBannerUrl);
                    setAvatar(data.avatar || defaultAvatarUrl);
                    setUsername(data.username);
                    setEmail(data.email);

                }
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
            }

        };

        fetchUserData();
        if (refreshNeeded) {
            setRefreshNeeded(false);
        }

    }, [isLoggedIn, userIsLoggedIn, userId, setAvatar, setBanner, setEmail, setUsername, refreshNeeded, setRefreshNeeded, db]);


    const handleBannerChange = async (e) => {
        setIsLoading(true); // Début du chargement
        if (!userId) {
            console.error("Test banner. UserId is undefined");
            return;
        }
        // console.log("Test banner. HandleBannerChange appelé");
        try {
            const file = e.target.files[0];
            const storageRef = ref(storage, `banners/${file.name}`);

            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            // console.log('Test banner Download URL:', downloadURL);

            setBanner(downloadURL);

            const userDoc = doc(db, 'users', userId); // Remplacez uid par userId
            await updateDoc(userDoc, {
                banner: downloadURL
            });
            setForceRender(prev => prev + 1);  // Forcer le rendu
            setImageChangeCounter(imageChangeCounter + 1); // Incrémenter le compteur d'image
        } catch (error) {
            console.log("Test banner. Erreur lors de l'upload: ", error);
            setErrorMsg("Une erreur s'est produite lors de la mise à jour de la bannière.");
        }
        setIsLoading(false); // Fin du chargement
    };


    const handleAvatarChange = async (e) => {
        setIsLoading(true); // Début du chargement
        if (!userId) {
            // console.error("Test avatar 06. UserId is undefined");
            return;
        }
        // console.log("Test avatar 05. HandleBannerChange appelé");
        try {
            const file = e.target.files[0];
            const storageRef = ref(storage, `avatars/${file.name}`);

            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            // console.log('Test avatar 03 Download URL:', downloadURL);

            setAvatar(downloadURL);

            const userDoc = doc(db, 'users', userId); // Remplacez uid par userId
            await updateDoc(userDoc, {
                avatar: downloadURL
            });
            setForceRender(prev => prev + 1);  // Forcer le rendu
            setImageChangeCounter(imageChangeCounter + 1); // Incrémenter le compteur d'image
            // setRefreshNeeded(true); // Forcer le re-rendu
        } catch (error) {
            // console.log("Test avatar 04. Erreur lors de l'upload: ", error);
            setErrorMsg("Une erreur s'est produite lors de la mise à jour de l'avatar.");
        }
        setIsLoading(false); // Fin du chargement
    };


    const openFilePicker = (inputType) => {
        // console.log('openFilePicker a été appelée');
        const inputId = inputType === 'banner' ? 'bannerFileInput' : 'avatarFileInput';
        const element = document.getElementById(inputId);
        if (element) {
            element.click();
        } else {
            console.error('Element not found');
        }
    };


    useEffect(() => {
        // Ceci est déclenché à chaque changement de forceRender
        console.log("Forcing re-render");
    }, [forceRender]);



    useEffect(() => {
        const fetchYouTubeKey = async () => {
            if (userData.favoriteMovie && userData.favoriteMovie.id) {
                try {
                    const videoResponse = await axios.get(
                        `https://api.themoviedb.org/3/movie/${userData.favoriteMovie.id}/videos?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
                    );
                    const videoData = videoResponse.data.results.find(
                        (video) => video.site.toLowerCase() === "youtube"
                    );
                    if (videoData) {
                        setVideoKey(videoData.key);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de la clé vidéo:", error);
                }
            }
        };

        fetchYouTubeKey();
    }, [userData.favoriteMovie]);

    useEffect(() => {
        const fetchFavoritePreviews = async () => {
            if (!userId) return;
            try {
                const userRef = doc(db, 'users', userId);
                const userDoc = await getDoc(userRef);
                const moviesData = userDoc.data().favoritePreviews || [];
                setFavoritePreviews(moviesData);
                const moviesData2 = userDoc.data().favoriteActors || [];
                setFavoriteActors(moviesData2);
                const moviesData3 = userDoc.data().favoriteCategories || [];
                setFavoriteCategories(moviesData3);
            } catch (error) {
                console.error("Erreur lors de la récupération des films favoris:", error);
            }
            // console.log("Test 2 - Films favoris: ", favoritePreviews);
        };


        if (isLoggedIn) {
            fetchFavoritePreviews();
        }
    }, [userIsLoggedIn, refreshNeeded, setRefreshNeeded, userId, favoritePreviews, setFavoritePreviews, db, isLoggedIn]);


    useEffect(() => {
        const fetchUpdatedUserData = async () => {
            try {
                // console.log('Test avatar 1. Debug userId:', userId);
                // console.log('Test avatar 2 Debug db:', db);
                let userRef = null; // Déclare userRef avant le bloc if
                if (userId && db) {
                    userRef = doc(db, 'users', userId);
                } else {
                    console.error('Either userId or db is undefined', userId, db);
                }

                if (userRef) { // Vérifie que userRef a été défini
                    const docSnap = await getDoc(userRef);


                    if (docSnap.exists()) {
                        setUserData(docSnap.data());
                        const { avatar, banner, username, email } = docSnap.data();
                        setBanner(banner || defaultBannerUrl);
                        setAvatar(avatar || defaultAvatarUrl);
                        setUsername(username);
                        setEmail(email);
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la mise à jour des données de l'utilisateur après modification de la bannière/avatar:", error);
            }
        };


        fetchUpdatedUserData();
        if (refreshNeeded) {
            setRefreshNeeded(false);
        }
    }, [banner, avatar, username, email, userId, db, setBanner, setAvatar, setUsername, setEmail, refreshNeeded, setRefreshNeeded]);

    useEffect(() => {
        const fetchFavoriteActors = async () => {
            if (!userId) return;
            try {
                const userRef = doc(db, 'users', userId);
                const userDoc = await getDoc(userRef);
                const actorsData = userDoc.data().favoriteActors || [];
                setFavoriteActors(actorsData);
            } catch (error) {
                console.error("Erreur lors de la récupération des acteurs favoris:", error);
            }
        };

        if (isLoggedIn) {
            fetchFavoriteActors();
        }
    }, [userIsLoggedIn, refreshNeeded, setRefreshNeeded, userId, favoriteActors, setFavoriteActors, db, isLoggedIn]);

    useEffect(() => {
        const fetchFavoriteCategories = async () => {
            if (!userId) return;
            try {
                const userRef = doc(db, 'users', userId);
                const userDoc = await getDoc(userRef);
                const categoriesData = userDoc.data().favoriteCategories || [];
                setFavoriteCategories(categoriesData);
            } catch (error) {
                console.error("Erreur lors de la récupération des catégories favorites:", error);
            }
        };

        if (isLoggedIn) {
            fetchFavoriteCategories();
        }
    }, [userIsLoggedIn, refreshNeeded, setRefreshNeeded, userId, favoriteCategories, setFavoriteCategories, db, isLoggedIn]);

    //cIndex with delay
    useEffect(() => {
        // Set a timer to change zIndex after 6 seconds
        const timerId = setTimeout(() => {
            setZIndex(1);  // Change zIndex to 1
        }, 6000);  // 6 seconds

        // Cleanup function to clear the timer if the component unmounts
        return () => {
            clearTimeout(timerId);
        };
    }, []);

    // console.log("userData.country:", userData.country);
    // console.log("userData.state:", userData.state);

    const displayName = isLoggedIn ? username : defaultUsername;

    return (
        <>

            {/* Ajout de Helmet pour le SEO */}
            <Helmet>
                <title>{`${displayName || "Utilisateur inconnu"} - Profil utilisateur`}</title>
                <meta name="description" content={`Découvrez le profil de ${displayName}, ses films favoris, ses acteurs préférés et bien plus encore.`} />
                <link rel="canonical" href={`https://moviefrenzy.ca/profil/${userId}`} />
            </Helmet>
            <div className="profilMember"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#200211",
                    opacity: "0.9",
                    overflowX: "hidden",
                    overflowY: "auto",
                    display: "block",
                    zIndex: "1000",
                }}
            >
                <div className='pc-Container'></div>
                <Navigation style={{ zIndex: "11000" }} />
                <div
                    className="transitionContainer"
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        top: "0",
                        left: "0",
                        zIndex: zIndex
                    }}
                >
                    {showAnimation && <EditTransition animationTriggered={true} />}
                    {/* <EditTransition animationTriggered={animationTriggered} /> */}
                    {/* { showAnimation? <EditTransition showAnimation={showAnimation} /> : null } */}
                </div>
                <div className="wrapper container-fluid">
                    <div className="row rowBanner">
                        <div className="col-12 col-md-12">
                            <div className="banner" style={{ backgroundImage: `url(${banner || defaultBannerUrl}?${imageChangeCounter})` }}>
                                <div className="div-circularAvatar-mobile" style={{ backgroundImage: `url(${avatar || defaultAvatarUrl}?${imageChangeCounter})` }}>
                                    <div className="div-circularAvatar" style={{ backgroundImage: `url(${avatar || defaultAvatarUrl}?${imageChangeCounter})`, zIndex: '150000' }}>
                                        <input type="file" id="avatarFileInput" style={{ display: 'none' }} onChange={(e) => handleAvatarChange(e, 'banner')} />
                                        <span className="avatarEditSpan" onClick={() => openFilePicker('avatar')}>
                                            <FontAwesomeIcon icon={faCameraRetro} size="lg" color="white" className='cameraRetro' style={{ transform: isMobile ? 'scale(0.5)' : 'scale(1)' }} />
                                        </span>
                                    </div>
                                </div>
                                <div className="divCircularNicknameMobile">
                                    {isMobile && <MobileCircularNicknameSvg username={displayName} />}
                                </div>
                                <input type="file" id="bannerFileInput" style={{ display: 'none' }} onChange={(e) => handleBannerChange(e, 'banner')} />
                                <span className="bannerEditSpan" onClick={() => openFilePicker('banner')}>
                                    <FontAwesomeIcon icon={faCameraRetro} size="lg" color="#D90093" className='cameraRetro' style={{ transform: isMobile ? 'scale(0.5)' : 'scale(1)' }} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row rowBio">
                        <div className="col-12 col-md-8 bioSection">
                            <div className="div-biseau">
                                <h2>MA BIOGRAPHIE</h2>
                                <span className='spanBio'>
                                    <blockquote className="citationProfil">
                                        <q>{userData.quote}</q>
                                    </blockquote>
                                    <div className='bioText' dangerouslySetInnerHTML={{ __html: userData.biography }}></div>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 bioSection-mobile">
                            <h2>MA BIOGRAPHIE</h2>
                            <blockquote className="citationProfil">
                                <q>{userData.quote}</q>
                            </blockquote>
                            <div className='bioText' dangerouslySetInnerHTML={{ __html: userData.biography }}></div>
                        </div>
                        <div className="col-12 col-md-4 avatarSection">
                            <div className="div-circularAvatar" style={{ backgroundImage: `url(${avatar || defaultAvatarUrl}?${imageChangeCounter})` }}>
                                <input type="file" id="avatarFileInput" style={{ display: 'none' }} onChange={(e) => handleAvatarChange(e, 'banner')} />
                                <span className="avatarEditSpan" onClick={() => openFilePicker('avatar')}>
                                    <FontAwesomeIcon icon={faCameraRetro} size="lg" color="#D90093" className='cameraRetroAvatar' />
                                </span>
                            </div>
                            <div className="div-circularNickname" style={{ margin: "0 0 3vh 0", paddingBottom: "-2vh" }}>
                                {!isMobile && <CircularNicknameSvg username={displayName} />}
                            </div>
                        </div>
                    </div>
                    <div className="row rowIdentify">
                        <div className="col-12 col-md-4">
                            <div className="identification1">
                                <h2 className='infoPersos' id='idInfoPerso'>Mes informations personnelles</h2>
                                <div className='blocInfo'>
                                    <div className='divGender'>
                                        <span>Sexe/Genre :</span>
                                        <span className='inputGender'>
                                            {mapGenderValueToDisplay(userData.gender)}
                                        </span>
                                    </div>
                                    <div className='divAge'>
                                        <span>Âge :</span>
                                        <span className='inputAge'>{userData.age}</span>
                                    </div>
                                    <div className='inputZodiacSignBase'>
                                        <span id='span1Zodiac' style={{ display: "inline-block", marginRight: "5vw" }}>Signe zodiac :</span>
                                        <figure className='inputZodiacSign'>
                                            <img src={zodiacImageUrl} alt={userData.zodiacSign} />
                                            <figcaption>{userData.zodiacSign}</figcaption>
                                        </figure>
                                    </div>
                                    <div className='divOccupation'>
                                        <span>Occupation :</span>
                                        <span className='inputOccupation'>
                                            {mapOccupationValueToDisplay(userData.occupation)}
                                        </span>
                                    </div>
                                    <div className='divPays'>
                                        <span>Mon pays :</span>
                                        <span className='inputPays'>{countryName}</span>
                                    </div>
                                    <div className='divEtatProvince'>
                                        <span>Ma province / état :</span>
                                        <span className='inputEtatProvince'>{String(stateName)}</span>
                                    </div>
                                    <div className='divVille'>
                                        <span>Ma ville :</span>
                                        <span className='inputVille'>{userData.city}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="identification2">
                                <h2>Mon profil cinéphile</h2>
                                <div className='preferContainer'>
                                    <h4>Mes films préférés :</h4>
                                    <div className='rowPrefer' id='rowPreferFilm'>
                                        {favoritePreviews.map(movie => (
                                            <Link to={`/ficheFilm/${movie.id}`} key={movie.id} >
                                                <PreviewMyFavoritesMovies key={movie.id} movie={movie} />
                                            </Link>
                                        ))}
                                    </div>
                                    <h4>Mes acteurs préférés :</h4>
                                    <div className='rowPrefer'>
                                        {favoriteActors.map(actor => (
                                            <Link to={`/actorDetails/${actor.id}`} key={actor.id}>
                                                <MyFavoritesActors key={actor.id} actor={actor} />
                                            </Link>
                                        ))}
                                    </div>
                                    <h4>Mes genres préférés :</h4>
                                    <div className='rowPrefer'>
                                        {favoriteCategories.map(category => (
                                            <My3FavoriteCategories key={category.id} category={category} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row rowFavoritePreview">
                        <div className="col-12 col-md-6">
                            <h3 className='h3CooupCoeur'>Ma preview coup de cœur !</h3>
                            <div className="aspect-ratio-container">
                                {videoKey ? (
                                    <YouTube
                                        videoId={videoKey}
                                        className="iFrameStyle"
                                        opts={videoOpts}
                                    // style={{opacity: '0'}}
                                    />
                                ) : (
                                    <p>Aucun film sélectionné comme coup de cœur.</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row rowEditionProfi">
                        <div className="col-12 col-md-6">
                            <button onClick={handleButtonEditionProfile}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{
                                    position: 'relative',
                                    color: isHovered ? '#FFFFFF' : '#00FFFF',
                                    backgroundImage: 'linear-gradient(to right, #D90093, #00FFFF)',
                                    backgroundSize: isHovered ? '100% 100%' : '0% 100%',
                                    border: '2px solid #D90093',
                                    borderRadius: "10px",
                                    width: '12vw',
                                    height: '7.5vh',
                                    fontSize: '1.60rem',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    backgroundRepeat: 'no-repeat',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    zIndex: 9999,
                                    overflow: 'hidden',
                                    fontWeight: 'bold',
                                    transition: '0.5s ease, color 0.5s ease',
                                    margin: '0 auto',
                                    boxShadow: '0 8px 16px rgba(217, 0, 147, 0.5)',
                                    backdropFilter: 'blur(0.5rem)',
                                    textShadow: '0 0 0.5rem rgba(0, 0, 0, 0.5)',
                                    textAlign: 'center',
                                    padding: '0.065rem',
                                }}>
                                Editer mon profil
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="actor-details-footer"
                style={{ maxWidth: '100%', overflowX: 'hidden', maxHeight: '100%', overflowY: 'hidden' }}
            >
                <Footer />
            </div>
        </>
    );
};

export default ProfilMember;















