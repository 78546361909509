
import React, { createContext, useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, onSnapshot } from "firebase/firestore";

export const OnlineUsersContext = createContext();

export const OnlineUsersProvider = (props) => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    // console.log("Test Provider01. OnlineUsersProvider a été monté");
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
        const newUsersList = [];
        snapshot.forEach((doc) => {
            // console.log("Test Provider03. Document data:", doc.data());
            newUsersList.push({ uid: doc.id, ...doc.data() });  // J'ai changé doc.data().userId à doc.id, ce qui est plus standard pour Firestore
        });

        if (JSON.stringify(newUsersList) !== JSON.stringify(onlineUsers)) {
            setOnlineUsers(newUsersList);
            // console.log("Test Provider02. onlineUsers mis à jour:", newUsersList);
        }
    });
  
    return () => unsubscribe();
}, [onlineUsers, db]);


  

  return (
    <OnlineUsersContext.Provider value={{ onlineUsers }}>
      {props.children}
    </OnlineUsersContext.Provider>
  );
};

export default OnlineUsersProvider;









