// React and Context
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext';

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { auth, db, storage } from '../firebasedeconfig';

// Components and Utils
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import RaindropAnimation from '../components/RaindropAnimation.js';
import saveImageUrlToFirestore from '../componentsofcompte/saveImageUrlToFirestore';  // Assurez-vous que ce chemin est correct
import { validateTest } from '../components/validate.js';

// Styles and Assets
import styles from '../styles/components/creerCompte.module.scss';
import 'boxicons/css/boxicons.min.css';
import { Link } from "react-router-dom";
import app from '../firebasedeconfig';
import { updateDoc } from "firebase/firestore";
import { Helmet } from "react-helmet"; // Ajout de Helmet pour le SEO


// import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const CreerCompte = () => {
    const [users, setUsers] = useState({ username: '', firstName: '', lastName: '', email: '', password: '', confirmPassword: '', userId: '' });
    const [errors, setErrors] = useState({});


    const handleInputChange = (event) => {
        setUsers({
            ...users,
            [event.target.name]: event.target.value
        });
    };

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [animationClass, setAnimationClass] = useState(''); // Nouvel état pour contrôler la classe d'animation

    const validationErrors = validateTest(users, true);
    const { updateContext, isLoggedIn, setIsLoggedIn } = useContext(UserContext);

    const [actionCodeSettings, setActionCodeSettings] = useState({
        url: 'http://localhost:3000/finishSignUp', // À remplacer par l'URL où l'utilisateur terminera l'inscription
        handleCodeInApp: true,
    });


    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [emailError, setEmailError] = useState(null);


    const handleSubmit = async event => {
        event.preventDefault();

        // Validez les données à nouveau
        const currentValidationErrors = validateTest(users, true);
        setErrors(currentValidationErrors);

        if (Object.keys(currentValidationErrors).length !== 0) return;

        console.log("Tentative de création de l'utilisateur...");

        try {
            // Créer l'utilisateur et obtenir userCredential
            const userCredential = await createUserWithEmailAndPassword(auth, users.email, users.password);

            console.log("Utilisateur créé : ", userCredential);
            console.log("userCredential: ", JSON.stringify(userCredential, null, 2));

            // Initialise l'url de la bannière et de l'avatar
            const defaultBannerUrl = "https://zupimages.net/up/23/35/57zz.png";
            const defaultAvatarUrl = "https://zupimages.net/up/23/35/ppgw.jpg";

            // Ajouter des informations supplémentaires dans Firestore
            await setDoc(doc(db, "users", userCredential.user.uid), {
                userId: userCredential.user.uid,
                username: users.username,
                firstName: users.firstName,
                lastName: users.lastName,
                email: users.email,
                banner: defaultBannerUrl,
                avatar: defaultAvatarUrl
            });

            // Mise à jour du contexte et du stockage local
            setUsername(users.username);
            setEmail(users.email);
            localStorage.setItem('username', users.username);
            updateContext(users.username, users.email, defaultBannerUrl, defaultAvatarUrl);
            setIsLoggedIn(true);
            setFormSubmitted(true);


            console.log("Tentative d'envoi du lien de connexion par email...");
            await sendSignInLinkToEmail(auth, users.email, actionCodeSettings);
            console.log("Lien de connexion envoyé avec succès.");
            window.localStorage.setItem('emailForSignIn', users.email);

            // Mettez à jour le statut en ligne dans Firestore
            await setDoc(doc(db, 'users', userCredential.users.uid), {
                isOnline: true,


            });
        } catch (error) {
            console.error("Erreur lors de la création de l'utilisateur ou de l'envoi de l'e-mail :", error);
        }
    };




    useEffect(() => {
        console.log("useEffect for isSignInWithEmailLink is running");
        if (isSignInWithEmailLink(auth, window.location.href)) {
            const email = window.localStorage.getItem('emailForSignIn');
            console.log("Inside isSignInWithEmailLink condition");
            if (email) {
                auth.signInWithEmailLink(email, window.location.href)
                    .then((result) => {
                        // Supprimez l'e-mail du localStorage
                        window.localStorage.removeItem('emailForSignIn');
                        // Utilisateur connecté
                        if (!isLoggedIn) { // Ajout de cette condition pour éviter la boucle
                            setIsLoggedIn(true);
                        }
                    })
                    .catch((error) => {
                        console.error("Erreur lors de la connexion :", error);
                    });
            }
        }
    }, []); // Pas de dépendance ici, donc exécuté une seule fois




    const [profileImageFile, setProfileImageFile] = useState(null);
    // const [profileImageFile, setProfileImageFile] = useState(user.profileImageUrl);

    const handleProfileImageFileUpload = (e) => {
        const file = e.target.files[0];
        setProfileImageFile(file);
    };

    const handleProfileImageChange = () => {
        document.getElementById('profileImageFileInput').click();
    };

    const [bannerUrl, setBannerUrl] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);


    const defaultBannerUrl = `${process.env.PUBLIC_URL}/images/demondragonTemple2.png`;


    useEffect(() => {
        const storageRef = ref(storage, `banners/demondragonTemple2.png`);
        getDownloadURL(storageRef).then((downloadURL) => {
            setBannerUrl(downloadURL);
        }).catch((error) => {
            console.log('Error getting download URL:', error);
            setBannerUrl(defaultBannerUrl); // Fallback to default image
        });
    }, []);


    const getYourImageUrlFromFirestore = async () => {
        const userRef = doc(db, "users", auth.currentUser?.uid);
        console.log('userRef:', userRef);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            return userDoc.data().profileImageUrl;
        } else {
            console.log("Aucun utilisateur trouvé !");
        }
    };



    useEffect(() => {
        // Vérifier si l'utilisateur est connecté
        if (auth.currentUser) {
            console.log('UID:', auth.currentUser.uid);
            // Récupération de l'URL de l'image depuis Firestore
            const fetchImageUrl = async () => {
                const url = await getYourImageUrlFromFirestore();
                // Mettre à jour l'état local ou le contexte
            };
            fetchImageUrl();
        } else {
            console.log('L\'utilisateur n\'est pas connecté.');
        }
    }, []);


    const somePromiseFunction = () => {
        return new Promise((resolve, reject) => {
            // Effectuez des opérations asynchrones ici...
            resolve("Résultat de l'opération asynchrone");
        });
    };

    const getUserFromFirestore = async (result) => {
        const userRef = doc(db, "users", result);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            return userDoc.data();
        } else {
            console.log("Aucun utilisateur trouvé !");
        }
    };



    useEffect(() => {
        console.log('test chatgpt4:', formSubmitted);
    }, [formSubmitted]);



    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formBackgroundColor = windowWidth < 567 ? "rgba(32, 2, 17, 0.9)" : "rgba(32, 2, 17, 0.2)";

    const [shouldDisplayRaindrop, setShouldDisplayRaindrop] = useState(false);

    console.log("État initial de formSubmitted :", formSubmitted);

    useEffect(() => {
        let timeout;
        if (formSubmitted) {
            console.log("formSubmitted est vrai. Déclenchement de l'animation...");
            timeout = setTimeout(() => {
                setShouldDisplayRaindrop(true);
            }, 3000);
        }
        return () => clearTimeout(timeout);  // annule le timeout si le composant est démonté avant le délai
    }, [formSubmitted]);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { setUsername, setEmail } = useContext(UserContext);

    const [loadingImage, setLoadingImage] = useState(false);
    const [errorLoadingImage, setErrorLoadingImage] = useState(null);
    // ...
    useEffect(() => {
        setLoadingImage(true);
        // Votre code pour récupérer l'image
        const storageRef = ref(storage, `banners/demondragonTemple2.png`);
        getDownloadURL(storageRef).then((downloadURL) => {
            setBannerUrl(downloadURL);

            // Votre code pour mettre à jour l'état local ou le contexte

        })
            .catch((error) => {
                console.log('Error getting download URL:', error);
                setErrorLoadingImage("Erreur lors du chargement de l'image.");
            })
            .finally(() => setLoadingImage(false));
    }, []);

    useEffect(() => {
        console.log("État de la connexion a changé:", isLoggedIn ? "Connecté" : "Déconnecté");
    }, [isLoggedIn]);



    return (
        <div className={styles.creerCompte}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundBlendMode: "overlay",
                backgroundColor: "#200211",
                opacity: "0.9",
                overflowX: "hidden",
                overflowY: "auto",
                display: "block",
                zIndex: "1",
            }}
        >
            {/* Ajout de Helmet */}
            <Helmet>
                <title>Créer un compte - MovieFrenzy</title>
                <meta name="description" content="Créez un compte sur MovieFrenzy pour sauvegarder vos films favoris, accéder à des recommandations personnalisées et bien plus encore." />
                <link rel="canonical" href="https://moviefrenzy.ca/creercompte" />
            </Helmet>
            <div className="video-container"
                style={{ zIndex: "-1" }}
            >
                <video autoPlay muted loop id="myVideo" style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    opacity: "0.2",
                    overflowX: "hidden",
                    overflowY: "auto",
                    display: "block",
                    visibility: windowWidth < 768 ? "hidden" : "visible",
                    zIndex: "-1",
                }}
                    className="video-background"
                >

                    <source src={`${process.env.PUBLIC_URL}/images/darkWaterMotion01.mp4`} type="video/mp4" />
                </video>
            </div>
            <div>

                <Navigation />

                <form
                    id="formId"
                    className={`${styles.container} ${formSubmitted ? `${styles['form-fade-out']} ${animationClass}` : animationClass}`}
                    onSubmit={handleSubmit}
                    style={{
                        backgroundColor: formBackgroundColor
                    }}
                >

                    <h2 className={styles.heading} style={{ color: formSubmitted ? '#00FFFF' : '#FFFFFF' }}>
                        {formSubmitted ? 'Compte enregistré' : 'Créer un compte'}
                    </h2>

                    <label className={styles.label}>Nom d'utilisateur :</label>
                    <div className={styles.row}>
                        <div className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="25px" style={{ marginTop: '-0.7vh' }}><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" className='nameUser' /></svg>
                        </div>
                        <input name="username" type="text" placeholder="Nom d'utilisateur" required onChange={handleInputChange} className={styles.input} />
                        {errors.username && <p className={styles.error}>{errors.username}</p>}
                    </div>

                    <div className={`${styles.row} ${styles.name}`}>
                        <input type="text" placeholder="Prénom" name="firstName" onChange={handleInputChange} className={styles.nameInput} />
                        {errors.firstName && <p className={styles.error}>{errors.firstName}</p>}
                        <input type="text" placeholder="Nom de famille" name="lastName" onChange={handleInputChange} className={styles.nameInput} />
                        {errors.lastName && <p className={styles.error}>{errors.lastName}</p>}
                    </div>

                    <label className={styles.label}>Email :</label>
                    <div className={styles.row}>
                        <div className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25px" style={{ marginTop: '-0.7vh' }}><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                        </div>
                        <input type="email" placeholder="Email" name="email" required onChange={handleInputChange} className={styles.input} autoComplete="username" />
                        {errors.email && <p className={styles.error}>{errors.email}</p>}
                        {emailError && <p className={styles.error}>{emailError}</p>}
                    </div>

                    <label className={styles.label}>Mot de passe :</label>
                    <div className={styles.row}>
                        <div className={styles.icon}>
                            {/* Icône de verrou */}
                            <i className="fas fa-lock"></i>
                        </div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Mot de passe"
                            name="password"
                            required
                            onChange={handleInputChange}
                            className={styles.input}
                            autoComplete="new-password" // Empêche le navigateur de proposer des mots de passe enregistrés
                        />
                        <div className={styles.eyeButton} onClick={togglePasswordVisibility}>
                            {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                        </div>
                        {errors.password && <p className={styles.error}>{errors.password}</p>}
                    </div>

                    <label className={styles.label}>Confirmer le mot de passe :</label>
                    <div className={styles.row}>
                        <div className={styles.icon}>
                            <i className="fas fa-lock"></i>
                        </div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Confirmer le mot de passe"
                            name="confirmPassword"
                            required
                            onChange={handleInputChange}
                            className={styles.input}
                            autoComplete="new-password" // Empêche le navigateur de proposer des mots de passe enregistrés
                        />
                        <div className={styles.eyeButton} onClick={togglePasswordVisibility}>
                            {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                        </div>
                        {errors.confirmPassword && <p className={styles.error}>{errors.confirmPassword}</p>}
                    </div>
                    <button type="submit" className={styles.button}>S'inscrire</button>
                    <span className={styles.span}>
                        VOUS AVEZ DÉJÀ UN COMPTE?<Link to="/Connexion" className={styles.link}>CONNECTEZ-VOUS</Link>
                    </span>
                </form>
                {shouldDisplayRaindrop && <RaindropAnimation />}
                {/* <div className='formsubmitted'
                    style={{ color: formSubmitted ? '#00FFFF' : '#FFFFFF' }}
                >
                    <h3>État de formSubmitted : {formSubmitted ? "Vrai" : "Faux"}</h3>
                </div>
                <div id='onAuthStateChanged'
                    style={{ color: isLoggedIn ? '#00FFFF' : '#FFFFFF' }}
                >
                    <h3>État de la connexion: {isLoggedIn ? "Connecté" : "Déconnecté"}</h3>
                </div> */}
                <Footer />
            </div>
        </div>
    );
};

export default CreerCompte;






















