/* global grecaptcha */

import React, { useState, useEffect, useContext, useCallback } from 'react';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import styles from '../styles/components/connexion.module.scss';
import { validateTest } from '../components/validate.js';
import { Link } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import 'boxicons/css/boxicons.min.css';
import { useNavigate } from 'react-router-dom';
import { defaultUsername, defaultEmail, defaultAvatarUrl } from '../constants';
import { sendPasswordResetEmail } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../UserContext';
import { Helmet } from "react-helmet";


const Connexion = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState('');
  const [banner, setBanner] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const history = useNavigate();

  const {
    setIsLoggedIn: fetchUserData, updateContext
  } = useContext(UserContext);

  const auth = getAuth();
  const db = getFirestore();
  // const userId = auth.uid;

  const [rememberMeChecked, setRememberMeChecked] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Essayez de vous connecter avec l'email et le mot de passe
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // Après une connexion réussie, accédez aux détails de l'utilisateur
      const user = userCredential.user;

      // Mise à jour du contexte utilisateur avec les données de connexion
      updateContext(user.displayName, user.email, user.photoURL, user.photoBanner, true);

      // Utilisez l'ID de l'utilisateur pour la redirection
      history(`/profilMemberPage/${user.uid}`);
    } catch (error) {
      // Si la connexion échoue, affichez l'erreur
      console.error("An error occurred during login: ", error);
      setErrorMessage(error.message);
    }
  };


  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
    } catch (error) {
      console.error(`Erreur : ${error.message}`);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(users => {
      if (users) {
        setIsLoggedIn(true);
        fetchUserData();
      } else {
        setIsLoggedIn(false);
        updateContext(null, null, null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Fenêtre modale ^pour mot de passe oublié

  const openModal = () => {
    console.log("Opening modal...");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("Closing modal...");
    setIsModalOpen(false);
  };

  const [message, setMessage] = useState(null); // Ajoutez ceci dans votre composant


  const [isHovered, setIsHovered] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Function to handle sign out
  const handleLogout = async () => {
    try {
      await auth.signOut();
      updateContext(defaultUsername, defaultEmail, defaultAvatarUrl, false); // Ajoutez le paramètre pour isLoggedIn
      history("/");
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    console.log("handleSubmit called");
    if (isLoggedIn) {
      handleLogout();
      return;
    }
  };

  useEffect(() => {
    if (window.grecaptcha) {
      grecaptcha.enterprise.ready(() => {
        console.log("grecaptcha est prêt !");
      });
    } else {
      console.error("grecaptcha n'est pas disponible.");
    }
  }, []);

  const onRecaptchaClick = (e) => {
    e.preventDefault();

    if (window.grecaptcha) {
      grecaptcha.enterprise.ready(async () => {
        try {
          const token = await grecaptcha.enterprise.execute('6LcJr5QqAAAAAA6DEG55o5i0fHtiTBZ62xa8zE3N', { action: 'LOGIN' });
          console.log("Token reçu : ", token);
          // Envoie ce token à ton backend pour vérification
        } catch (err) {
          console.error("Erreur avec reCAPTCHA :", err);
        }
      });
    } else {
      console.error("grecaptcha n'est pas défini.");
    }
  };





  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className={styles.connexion}
      style={{
        backgroundImage: 'url("https://zupimages.net/up/23/32/g815.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        overflowX: "hidden",
        overflowY: "auto", // Changé ici
        zIndex: "1",
        height: "100%", // Changé ici
        width: "100%",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        display: "grid", // Ajouté ici
        gridTemplateRows: "auto 1fr auto" // Ajouté ici
      }}
    >
      <Helmet>
        <title>Connexion - MovieFrenzy</title>
        <meta name="description" content="Connectez-vous pour accéder à votre profil MovieFrenzy, ajouter des films à vos favoris, et profiter d'une expérience personnalisée." />
        <link rel="canonical" href="https://moviefrenzy.ca/connexion" />
      </Helmet>
      <Navigation />
      <div className={styles.connexionContainer}>
        <div className={styles.wrapper}>
          <form onSubmit={handleLogin} className='formConnexion'
            style={{
              opacity: isModalOpen ? 0.15 : 1
            }}
          >
            <h1>Connexion</h1>
            <div className={styles.inputBox1}>
              <input
                type="email"
                placeholder="Votre courriel"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"  // ajouté
              />              <i className='bx bxs-user'></i>
            </div>
            <div className={styles.inputBox2}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Mot de passe"
                name="password"
                required
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"  // ajouté
              />
              <i className='bx bxs-lock-alt'></i>
              <div className={styles.passwordMaskContainer}>
                <button type="button" onClick={togglePasswordVisibility} className={styles.passwordMask}>
                  {showPassword ? <i className='bx bx-hide'></i> : <i className='bx bx-show'></i>}
                </button>
              </div>
            </div>
            <div className={styles.remeber}>
              <label className={styles.remeber}>
                <input
                  type="checkbox"
                  className={styles.customCheckbox}
                  checked={rememberMeChecked} // Ajouté pour refléter l'état
                  onChange={(e) => setRememberMeChecked(e.target.checked)} // Mettre à jour l'état
                />
                Se souvenir de moi
              </label>
              <div className={styles.forgetpasswordMother}>
                <button type="button" onClick={openModal} className={styles.forgetpassword}>Mot de passe oublié ?</button>
              </div>
            </div>
            <button type="submit" className={styles.btn}>
              {isLoggedIn ? "Déconnexion" : "Connexion"}
            </button>
            {message && <div>{message}</div>}
            <div className={styles.register}>
              <p>Vous n'avez pas encore de compte ? </p>
              <Link to="/creercompte">S'inscrire</Link>
            </div>
          </form>
          {/* Modale */}
          {console.log(isModalOpen)}
          {isModalOpen && (
            <div className="modal"
              style={{
                display: isModalOpen ? 'flex' : 'none',
                position: 'fixed',
                width: '50vw',
                height: '50vh',
                top: '30vh',
                left: '25vw',
                backgroundBlendMode: "overlay",
                backgroundColor: isModalOpen ? 'rgba(0,0,0,0.7)' : 'rgba(255,51,204,1.0)',
                color: '#FF33CC',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9991,
                border: '3px solid #00FFFF',
                fontSize: '1rem',
                fontWeight: 'normal',
                flexDirection: 'column',
                textAlign: 'center',
                padding: '1rem',
                gap: '1rem',
                borderRadius: '10px',
                overflowY: 'hidden',  /* Masque les barres de défilement verticales */
                overflowX: 'hidden', /* Masque les barres de défilement horizontales */

              }}
            >
              <div className="containerVid"
                style=
                {{
                  position: 'absolute',
                  width: '100vw',
                  height: '100vh',
                  top: 0,
                  left: 0,
                  zIndex: 9997,
                  display: isHovered ? "block" : "none",
                  visibility: windowWidth < 768 ? "hidden" : "visible",
                  overflowX: "hidden",
                  overflowY: "auto",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  backgroundBlendMode: "overlay",
                  backgroundColor: isModalOpen ? 'rgba(0,0,0,0.7)' : 'rgba(255,51,204,1.0)',
                  opacity: "0.74",
                }}
              >
                <video autoPlay muted loop id="myVideo" style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  backgroundBlendMode: "overlay",
                  width: "100vw",
                  height: "100vh",
                  objectFit: "cover",
                  opacity: "0.2",
                  overflowX: "hidden",
                  overflowY: "auto",
                  display: isHovered ? "block" : "none",
                  visibility: windowWidth < 768 ? "hidden" : "visible",
                  zIndex: "9998",
                }}
                  className="video-background"
                >

                  <source src={`${process.env.PUBLIC_URL}/images/Severalturquoiseflashes02.mp4`} type="video/mp4" />
                </video>
              </div>
              <h2>Réinitialiser le mot de passe</h2>
              <input
                type="email"
                placeholder="Entrez votre adresse e-mail"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                style={{
                  width: isMobile ? '85%' : '35%',
                  height: '4vh',
                  borderRadius: '10px',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  color: isMobile ? '#00FFFF' : 'inherit',
                  outline: 'none',
                  border: '1px solid #00FFFF',
                  fontSize: isMobile ? '0.65rem' : '1rem',
                  padding: '0.5rem'
                }}
                id='inputEmailEntrez'
              />
              <button onClick={onRecaptchaClick} className={styles.btn}>
                Vérifier avec reCAPTCHA
              </button>
              <button onClick={() => handleForgotPassword(resetEmail)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  position: 'relative',
                  color: isHovered ? '#FFFFFF' : '#00FFFF',
                  backgroundImage: 'linear-gradient(to right, #D90093, #00FFFF)',
                  backgroundSize: isHovered ? '100% 100%' : '0% 100%',
                  border: '1.5px solid #D90093',
                  borderRadius: "10px",
                  width: isMobile ? '35vw' : '9vw',
                  height: '4.5vh',
                  fontSize: '1.25rem',
                  backgroundColor: 'transparent',
                  backgroundRepeat: 'no-repeat',
                  outline: 'none',
                  cursor: 'pointer',
                  zIndex: 9999,
                  overflow: 'hidden',
                  fontWeight: 'bold',
                  transition: '0.5s ease, color 0.5s ease',
                }}>
                Envoyer
              </button>
              <button onClick={closeModal}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                  color: isHovered ? '#FF33CC' : '#00FFFF',
                  transform: isHovered ? 'scale(1.5)' : 'scale(1)',
                  background: 'none',
                  border: 'none',
                  fontSize: isMobile ? '0.85rem' : '1.5rem',
                  zIndex: 9999,
                }}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
              {message && <div style={{ color: '#00FFFF', fontWeight: 'bold', zIndex: '10000' }}>{message}</div>}

            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Connexion;
