import React, { useState } from 'react';
import { useRive } from '@rive-app/react-canvas';
import AnimeGirlRiv from './6421-12447-anime-girl.riv';
import UseWindowSize from '../components/UseWindowSize';

export function AnimeGirl() {
    const { width } = UseWindowSize();
    const isMobile = width < 768;
    const [hover, setHover] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const marginRightValue = isMobile ? '3.5vw' : isLoggedIn ? '3.5vw' : '7.5vw';
    const marginTopValue = isMobile ? '-10.5vh' : '-10vh';

    const { rive, RiveComponent } = useRive({
        src: AnimeGirlRiv,
        autoplay: true,
    });

    const hoverStyles = hover ? {
        filter: 'brightness(1)',
        dropShadow: isMobile ? '0 0 7.5px #00FFFF' : '0 0 10px #00FFFF',
        border: 'solid #00FFFF',
        boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
        transform: 'scale(1.2)',
        transitionDuration: '0.25s'
    } : {};

    return (
        <RiveComponent 
            style={{
                width: isMobile ? '15vw' : '30vw',
                height: isMobile ? '9.5vh' : '45vh',
                maxWidth: '180px',
                maxHeight: '270px',
                float: 'right',
                marginRight: marginRightValue,
                marginTop: marginTopValue,
                border: '2px solid #FFF',
                cursor: 'pointer',
                msTransitionDuration: '0.25s',
                filter: 'brightness(0.8)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                backgroundColor: 'rgba(32, 2, 17, 0.95)',
                transition: 'all 0.15s ease-in-out',
                ...hoverStyles
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />
    );
}

