import React from "react";
import { Link } from "react-router-dom";

const My3FavoriteCategories = (props) => {
    const { category } = props;

    return (
        <div className="previewMy3FavoriteCategories">
            <Link to={`/categories/${category.id}`}>
                <div className="category-container">
                    <h4 className="categorieFavoriteEffect"
                        style={{
                           color: "#D90093",
                        }}
                    >
                        {category.name}
                    </h4>
                </div>
            </Link>
        </div>
    )
}

export default My3FavoriteCategories;
