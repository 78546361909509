// AnimationContext.js
import React, { createContext, useContext, useState } from 'react';

const AnimationContext = createContext();

export const useAnimationContext = () => useContext(AnimationContext);

export const AnimationProvider = ({ children }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const handleTriggerAnimation = () => {
    setTriggerAnimation(!triggerAnimation);
  };

  return (
    <AnimationContext.Provider value={{ triggerAnimation, handleTriggerAnimation }}>
      {children}
    </AnimationContext.Provider>
  );
};

export { AnimationContext };

