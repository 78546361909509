import React, { useState, useEffect } from "react";
import NavigationBar from "../components/Navigation";
import PopularMovies from "../components/PopularMovies";
import Footer from "../components/Footer";



const FilmPop = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "overlay",
          backgroundColor: "#200211",
          opacity: "0.9",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <NavigationBar />
        <div style={{ marginTop: "50px" }}>
          <PopularMovies />
        </div>
        <Footer />
      </div>
    );
  };
  
  export default FilmPop;