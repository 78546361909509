
import * as Sentry from "@sentry/react";

export const initializeSentry = () => {
  Sentry.init({
    dsn: "https://d9071d572bbf2cd65d1a5ddcbb3887af@o4505922928312320.ingest.sentry.io/4505922936897536",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
};


