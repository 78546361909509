import React from "react";
import Navigation from "../components/Navigation";
import SearchMovie from "../components/SearchMovie";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet"; // Ajout pour les balises SEO

const Recherche = () => {
  return (
    <div
      className="search"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundBlendMode: "overlay",
        backgroundColor: "#200211",
        opacity: "0.9",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {/* Helmet pour le SEO */}
      <Helmet>
        <title>{`Résultats pour "${SearchMovie}" - MovieFrenzy`}</title>
        <meta
          name="description"
          content={`Découvrez les résultats pour "${SearchMovie}" parmi les films et acteurs sur MovieFrenzy.`}
        />
        <meta property="og:title" content={`Résultats pour "${SearchMovie}"`} />
        <meta
          property="og:description"
          content={`Découvrez les résultats pour "${SearchMovie}" parmi les films, acteurs et bandes-annonces.`}
        />
      </Helmet>

      <Navigation />
      <div
        style={{
          backgroundColor: "#200211",
          opacity: "0.9",
          fontFamily: "Bebas Neue",
          padding: "0 16px",
          maxWidth: "33.33%",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          border: "2.5px solid #D90093",
          marginTop: "50px",
          height: "clamp(50px, 15vw, 125px)",
        }}
      >
        <h1
          style={{
            color: "#D90093",
            fontSize: "clamp(2rem, 4vw, 4rem)",
            textAlign: "center",
          }}
        >
          Recherche
        </h1>
      </div>
      <SearchMovie />
      <Footer />
    </div>
  );
};

export default Recherche;