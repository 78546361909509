import React from 'react';
import '../styles/components/LoadingSpinner.scss';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
      Loading...
    </div>
  );
};

export default LoadingSpinner;
