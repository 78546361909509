// AnimationTriggerUpdater.js
import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimationContext } from './AnimationContext';

const AnimationTriggerUpdater = () => {
  const { triggerAnimation, handleTriggerAnimation } = useContext(AnimationContext);
  const location = useLocation();

  useEffect(() => {
    // Déclenchez l'animation uniquement si le chemin est '/' et que triggerAnimation est false
    if (location.pathname === '/' && !triggerAnimation) {
      handleTriggerAnimation();
    }
    // Si vous souhaitez réinitialiser l'état lorsque le chemin n'est plus '/',
    // vous pouvez ajouter une autre condition ici.
  }, [location, triggerAnimation, handleTriggerAnimation]);

  return null;
};

export default AnimationTriggerUpdater;
