import React from "react";
import { useNavigate, Link } from "react-router-dom";
import "../styles/components/footer.scss";


const Footer = () => {
    const history = useNavigate();

    return (
        <footer className="footer" 
            style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        >
            <div className="triangle-logo row">
                <div className="triangle-top col-12"></div>
                <div className="text-center logo-container">
                    <Link to="/" onClick={() => history("/")}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/colorLogoMovieFrenzyE.svg`}
                            alt="Movie Frenzy E logo"
                            id="logoFooter"
                            style={{ backgroundColor: "transparent" }}
                        />
                    </Link>
                </div>
            </div>
            <div className="footer-container container-fluid">
                <div className="row linkFormu w-100">
                    <div className="left col-12 col-md-6">
                        <div className="row linkrow">
                            <div className="column col-6 lesLinks">
                                <h2>ENTREPRISE</h2>
                                <div className="links">
                                    <button className="footer-link" onClick={() => { }}>
                                        Conditions
                                    </button>
                                    <button className="footer-link" onClick={() => { }}>
                                        Politique de confidentialité
                                    </button>
                                    <button className="footer-link" onClick={() => { }}>
                                        Cookies
                                    </button>
                                    <button className="footer-link" onClick={() => { }}>
                                        Assistance
                                    </button>
                                    <button className="footer-link" onClick={() => { }}>
                                        Partenaires
                                    </button>
                                </div>
                            </div>
                            <div className="column col-6 lesLinks">
                                <h2>ACCÈS RAPIDE</h2>
                                <div className="links">
                                    <Link to="/filmsPopulaires">
                                        <button className="footer-link">Films populaires</button>
                                    </Link>
                                    <button className="footer-link" onClick={() => { }}>
                                        Catégories
                                    </button>
                                    <button
                                        className="footer-link"
                                        onClick={() => history("/acteur")}
                                    >
                                        Acteurs
                                    </button>
                                    <button className="footer-link" onClick={() => { }}>
                                        À propos
                                    </button>
                                    <button className="footer-link" onClick={() => { }}>
                                        Connexion
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right col-12 col-md-6" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/metropolisBW.png)` }}>
                        <div className="newsletter">
                            <h2 className="newsletter-title">
                                INSCRIS TOI À NOTRE INFOLETTRE
                            </h2>
                            <div className="input-container">
                                <input type="text" placeholder="Ton adresse email" />
                                <button className="submit-button">ENVOYER</button>
                            </div>
                        </div>
                        <div className="social-icons row">
                            <div className="col-2">
                                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                    <img src={process.env.PUBLIC_URL + "/images/Social Networks/facebook.png"} alt="Facebook" className="img-fluid networkIcon" />
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                    <img src={process.env.PUBLIC_URL + "/images/Social Networks/twitter.png"} alt="Twitter" className="img-fluid networkIcon" />
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                    <img src={process.env.PUBLIC_URL + "/images/Social Networks/instagram.png"} alt="Instagram" className="img-fluid networkIcon" />
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                    <img src={process.env.PUBLIC_URL + "/images/Social Networks/linkedin.png"} alt="LinkedIn" className="img-fluid networkIcon" />
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                    <img src={process.env.PUBLIC_URL + "/images/Social Networks/youtube.png"} alt="YouTube" className="img-fluid networkIcon" />
                                </a>
                            </div>
                            <div className="col-2">
                                <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
                                    <img src={process.env.PUBLIC_URL + "/images/Social Networks/pinterest.png"} alt="Pinterest" className="img-fluid networkIcon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyR row">
                    <div className="col-12">
                        2023 MovieFrenzy. Tous droits réservés<span>&nbsp;©</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;



