import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false); // Nouvel état pour "active"

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const baseStyle = {
    display: isVisible ? 'inline-block' : 'none',
    backgroundColor: '#FF33CC',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    borderRadius: '25px',
    position: 'fixed',
    bottom: '30px',
    right: '30px',
    transition: 'background-color .3s',
    zIndex: '1000',
    border: 'none',
    outline: 'none',
    color: 'white',
    fontSize: '20px',
    padding: '10px',
    cursor: 'pointer',
    marginBottom: '50px',
  };

  const hoverStyle = {
    backgroundColor: '#00FFFF',
  };

  const activeStyle = {
    backgroundColor: '#D90093',
  };

  let buttonStyle = {...baseStyle};
  if (isHovered) buttonStyle = {...buttonStyle, ...hoverStyle};
  if (isActive) buttonStyle = {...buttonStyle, ...activeStyle};

  return (
    <button
      onClick={scrollToTop}
      style={buttonStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsActive(true)} // Appliquer le style "active" lors de l'enfoncement
      onMouseUp={() => setIsActive(false)}   // Retirer le style "active" lors du relâchement
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
};

export default BackToTopButton;


