import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useNavigate } from "react-router-dom";

const SearchMovie = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const history = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };


  useEffect(() => {
    const URL = `https://api.themoviedb.org/3/search/movie?api_key=e16900047627f56531a2d3d8d4df9e92&language=en-US&query=${query}&page=${currentPage}`;

    axios.get(URL).then((res) => {
      setData(res.data.results);
    });
  }, [query, currentPage]);

  // Fonction qui remplace l'image manquante avec une image de remplacement
  const replaceNoPoster = (event) => {
    event.target.onerror = null; // Pour éviter une boucle infinie si la substitution ne fonctionne pas
    event.target.src = "https://via.placeholder.com/200x300.png?text=Aucune+image+pour+ce+film";
    event.target.alt = "Aucune image pour ce film";
    event.target.style = "object-fit: contain; background-color: #200211; color: #D90093; font-size: clamp(1.5rem, 3vw, 2rem); display: flex; align-items: center; justify-content: center;";
  };

  const [resultCount, setResultCount] = useState('top3');

  return (
    <div className="searchMovie">
      <div className="input-container">
        <input className="input" placeholder="Entrez un mot clé" onChange={(e) => setQuery(e.target.value)}></input>
      </div>
      {query ? (
        <ul className="searchMovie-list">
          {data.map((m) => (
            <Movie
              movie={m}
              key={m.id}
              onClickMovie={() => history("/FicheFilm", { id: m.id })}
              onError={replaceNoPoster} // Gestionnaire d'erreur pour remplacer l'image manquante
            />
          ))}
        </ul>
      ) : (
        <div className="placeholder-container">
          <h4>Rien à afficher, veuillez entrer votre mot de recherche</h4>
        </div>
      )}
      <div className="pagination">
        <button onClick={prevPage}>Page précédente</button>
        <span className="currentPage">Page {currentPage}</span>
        <button onClick={nextPage}>Page suivante</button>
      </div>
    </div>
  );
};

export default SearchMovie;