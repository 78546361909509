import React, { createContext, useState } from "react";

export const RefreshContext = createContext();

export const RefreshProvider = ({ children }) => {
    const [refreshNeeded, setRefreshNeeded] = useState(false);

    return (
        <RefreshContext.Provider value={{ refreshNeeded, setRefreshNeeded }}>
            {children}
        </RefreshContext.Provider>
    );
};