
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Movie = (props) => {
    const { movie, additionalClass, borderStyle, titleColor, titleEffectVignette, titleDropEffectVignette } = props; // Utiliser titleEffectVignette pour le textShadow
    const [isHovered, setIsHovered] = useState(false); // État pour savoir si la carte est survolée

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={`movie ${additionalClass || ''}`}
            style={{ border: isHovered ? borderStyle : 'none' }}  // Applique la bordure uniquement au survol
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Link to={`/ficheFilm/${movie.id}`}>
                <img
                    src={movie.poster_path
                        ? `https://image.tmdb.org/t/p/original/${movie.poster_path}`
                        : "https://zupimages.net/up/23/13/yd61.jpg"}
                    alt={movie.title}
                />
                <div className="conteneur">
                    {/* Appliquer la couleur du titre en fonction de la bordure et l'ombre textuelle */}
                    <li className="titre" style={{
                        color: titleColor,
                        textShadow: titleEffectVignette,  // Appliquer l'effet de texte selon la fête avec textShadow
                        zIndex: isHovered ? 1 : 0,  // Assurez-vous que le texte est au premier plan
                        filter: titleDropEffectVignette, // Appliquer l'effet de chute de texte selon la fête avec filter
                    }}>
                        {movie.title}
                    </li>
                </div>
            </Link>
        </div>
    );
};

export default Movie;









