import React from "react";
import { useParams } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import CategoriesMovies from "../components/CategoriesMovies";

const Categories = () => {
  const { selectedCategory } = useParams();

  return (
    <div
      className="categoriesMovies"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundBlendMode: "overlay",
        backgroundColor: "#200211",
        opacity: "0.9",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Navigation />
      <div className="categories-lista">
        <CategoriesMovies selectedCategory={selectedCategory} />
      </div>
      <Footer />
    </div>
  );
};

export default Categories;


