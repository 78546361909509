import React, { useState, useEffect, useContext, useCallback } from "react";
import NavigationBar from "../components/Navigation";
import axios from "axios";
import { Container, Button } from "reactstrap";
import Actor from "../components/Actor";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Genre from "../components/Genre";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart, ArcElement, LineElement, BarElement, PointElement, BarController, LineController, PieController, CategoryScale, LinearScale, RadialLinearScale, TimeScale } from 'chart.js';
import ActeurContext from '../contexts/ActeurContext';
import { UserContext } from '../UserContext';
import Flag from 'react-world-flags';
// UserContext.js
import { db, auth } from "../firebasedeconfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Helmet } from "react-helmet"; // Ajout de Helmet pour le SEO
// import { useAuthState } from "react-firebase-hooks/auth";
// import { useCollectionData } from "react-firebase-hooks/firestore";
console.log("Imported Firestore instance: ", db);




Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    LineController,
    PieController,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    TimeScale
);


const FicheFilm = (props) => {
    console.log("Instance de Firestore dans FicheFilm:", db);
    // const [users] = useAuthState(auth);
    const { id } = useParams();
    const [movie, setMovie] = useState({});
    const [detail, setDetail] = useState({
        director: "",
        writer: "",
        age_rating: "",
    });
    const [genres, setGenres] = useState([]);
    const [actors, setActors] = useState([]);
    const [credits, setCredits] = useState([]);
    const history = useNavigate();
    const [videoKey, setVideoKey] = useState("");
    const publicPercent = (detail.vote_average * 10).toFixed(2);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { updateContext, isLoggedIn, userIsLoggedIn } = useContext(UserContext);
    const { userId } = useContext(UserContext);

    const videoOptions = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1, // Lecture automatique
            color: 'white', // Couleur de la barre de progression
            controls: 1, // Afficher les contrôles
            modestbranding: 1, // Cacher le logo YouTube
            rel: 0, // Ne pas montrer d'autres vidéos à la fin
            fs: 1, // Afficher le bouton de plein écran
            cc_load_policy: 1, // Activer les sous-titres par défaut
            iv_load_policy: 3, // Ne pas afficher les annotations vidéo
            enablejsapi: 1, // Permettre l'API JavaScript pour Chromecast
            origin: window.location.origin // Définir l'origine pour l'API JavaScript
        }
    };

    const _onReady = (event) => {
        event.target.playVideo();
    };


    console.log("Test FicheFilm FavoriteMovie01. isLoggedIn dans FicheFilm:", isLoggedIn);
    console.log("Test FicheFilm FavoriteMovie02 .userId dans FicheFilm:", userId);
    console.log("Test FicheFilm FavoriteMovie03. detail dans FicheFilm:", detail);


    const handleActeurClick = (acteurId) => {
        history(`/acteurDetails/${acteurId}`);
    };

    const handleCategoryClick = (categoryId) => {
        history(`/categories/${categoryId}`);
    };



    // Fonction mémorisée pour récupérer les détails du film, les crédits et la bande-annonce
    const fetchAllMovieDetails = useCallback(async () => {
        try {
            const [detailResponse, creditsResponse, videoResponse, ageRatingResponse] = await Promise.all([
                axios.get(`https://api.themoviedb.org/3/movie/${id}?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`),
                axios.get(`https://api.themoviedb.org/3/movie/${id}/credits?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`),
                axios.get(`https://api.themoviedb.org/3/movie/${id}/videos?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`),
                axios.get(`https://api.themoviedb.org/3/movie/${id}/release_dates?api_key=e16900047627f56531a2d3d8d4df9e92`)
            ]);

            const movieDetails = detailResponse.data;
            const movieCredits = creditsResponse.data;
            const movieVideos = videoResponse.data.results;
            const movieAgeRatings = ageRatingResponse.data.results;

            // Logique pour mettre à jour l'état avec les détails du film
            setDetail(movieDetails);
            setGenres(movieDetails.genres);
            setActors(movieCredits.cast);

            // Logique pour trouver la bande-annonce YouTube
            const videoData = movieVideos.find(video => video.site.toLowerCase() === "youtube");
            if (videoData) {
                setVideoKey(videoData.key);
            } else {
                console.log("Pas de bande-annonce disponible");
                setVideoKey(null);
            }

            // Logique pour définir le réalisateur et le scénariste
            const director = movieCredits.crew.find(crew => crew.job.toLowerCase() === "director");
            const writer = movieCredits.crew.find(crew => crew.job.toLowerCase() === "screenplay" || crew.job.toLowerCase() === "writer");

            if (director) {
                setDetail(prevDetail => ({ ...prevDetail, director: director.name }));
            }

            if (writer) {
                setDetail(prevDetail => ({ ...prevDetail, writer: writer.name }));
            }

            // Logique pour la classification par âge
            const ageRating = movieAgeRatings.find(result => result.iso_3166_1.toLowerCase() === "fr");
            if (ageRating && ageRating.release_dates[0]) {
                setDetail(prevDetail => ({ ...prevDetail, age_rating: ageRating.release_dates[0].certification }));
            }

        } catch (error) {
            console.log(error);
        }
    }, [id]); // Les dépendances de useCallback

    // Utilisation de la fonction mémorisée comme dépendance de useEffect
    useEffect(() => {
        fetchAllMovieDetails();
    }, [fetchAllMovieDetails]);


    const renderGenres = (genreIds) => {
        return genreIds.map((id) => {
            const genre = genres.find((g) => g.id === id);
            if (genre) {
                return <Genre key={id} id={genre.id} name={genre.name} />;
            }
            return null;
        });
    };


    // Doughnut chart data and options
    const doughnut = {
        datasets: [
            {
                data: [publicPercent, 100 - publicPercent],
                backgroundColor: ["#FF33CC", "#333333"],
                borderWidth: 0,
            },
        ],
    };

    const [isVisible, setIsVisible] = useState(false);

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        animation: {
            duration: isVisible ? 1000 : 0,
        },
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    }
                });
            },
            { threshold: 0.5 }
        );

        const chartElement = document.querySelector('.chart-container');

        if (chartElement) {
            observer.observe(chartElement);
        }

        return () => {
            if (chartElement) {
                observer.unobserve(chartElement);
            }
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const detailResponse = await axios.get(
                `https://api.themoviedb.org/3/movie/${id}?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
            );
            setDetail(detailResponse.data);
            setGenres(detailResponse.data.genres);

            const creditsResponse = await axios.get(
                `https://api.themoviedb.org/3/movie/${id}/credits?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
            );
            setActors(creditsResponse.data.cast);

            const videoResponse = await axios.get(
                `https://api.themoviedb.org/3/movie/${id}/videos?api_key=e16900047627f56531a2d3d8d4df9e92&language=fr-FR`
            );
            const videoData = videoResponse.data.results.find((video) => video.site.toLowerCase() === "youtube");
            if (videoData) {
                setVideoKey(videoData.key);
            } else {
                console.log("Pas de bande-annonce disponible");
                setVideoKey(null);
            }

            // Récupérer les informations sur le réalisateur et le scénariste
            const director = creditsResponse.data.crew.find(
                (crew) => crew.job.toLowerCase() === "director"
            );
            const writer = creditsResponse.data.crew.find(
                (crew) => crew.job.toLowerCase() === "screenplay" || crew.job.toLowerCase() === "writer"
            );

            // Récupérer les informations sur la classe de maturité
            const ageRatingResponse = await axios.get(
                `https://api.themoviedb.org/3/movie/${id}/release_dates?api_key=e16900047627f56531a2d3d8d4df9e92`
            );
            const ageRating = ageRatingResponse.data.results.find(
                (result) => result.iso_3166_1.toLowerCase() === "fr"
            );
            if (ageRating) {
                setDetail((prevDetail) => ({ ...prevDetail, age_rating: ageRating.release_dates[0].certification }));
            }
        };
        fetchData();
    }, [id]);

    console.log(actors);


    const addFavoritePreview = async () => {
        console.log("Test FicheFilm FavoriteMovie04. isLoggedIn  dans addFavoritePreview:", isLoggedIn);
        console.log("Test FicheFilm FavoriteMovie05. detail dans addFavoritePreview:", detail);
        try {
            // Assurez-vous que l'utilisateur est connecté et que le film est défini
            if (!isLoggedIn || !detail) return;

            // l'ID de l'utilisateur doit être accessible via l'authentification Firebase
            const userId = auth.currentUser.uid;

            // Référence du document utilisateur dans Firestore
            const userRef = doc(db, "users", userId);

            // Récupérez les données de l'utilisateur actuel
            const userSnap = await getDoc(userRef);
            const userData = userSnap.data();

            // Assurez-vous que l'utilisateur existe dans Firestore
            if (!userSnap.exists()) {
                console.error("No such user!");
                return;
            }

            // Ajoutez le film actuel aux previews favorites de l'utilisateur
            const newFavoritePreviews = [...(userData.favoritePreviews || []), detail];

            // Mettez à jour Firestore avec les nouvelles previews favorites
            await updateDoc(userRef, {
                favoritePreviews: newFavoritePreviews
            });
        } catch (error) {
            console.error("Error adding favorite preview:", error);
        }
        setShowConfirmation(true);
        setTimeout(() => {
            setShowConfirmation(false);
        }, 3000);
    };






    return (
        <React.Fragment>
            {/* Helmet pour le SEO */}
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Movie",
                        "name": detail.title,
                        "genre": genres.map(genre => genre.name),
                        "director": {
                            "@type": "Person",
                            "name": detail.director
                        },
                        "datePublished": detail.release_date,
                        "actor": actors.map(actor => ({
                            "@type": "Person",
                            "name": actor.name
                        })),
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": detail.vote_average,
                            "reviewCount": detail.vote_count
                        }
                    })}
                </script>
            </Helmet>
            <NavigationBar />

            <ActeurContext.Provider value={{ handleActeurClick }}>
                <div className="ficheFilm" style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/darkwater.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "#200211",
                    opacity: "0.9",
                    overflowX: "hidden",
                    overflowY: "auto",
                }}>
                    <div className="video-wrapper">
                        <div className="video-background">
                            {videoKey ? (
                                <div className="video-overlay">
                                    <div className="youtube-container">
                                        <YouTube
                                            videoId={videoKey}
                                            opts={videoOptions}
                                            onReady={_onReady}
                                        />
                                        <img src="../images/colorLogoMovieFrenzyE.svg" alt="logo" className="myLogoToutube" />
                                        <google-cast-launcher> </google-cast-launcher>
                                    </div>
                                </div>
                            ) : (
                                <div className="no-video-background">
                                    <div className="poster-background" style={{ backgroundImage: `url('https://image.tmdb.org/t/p/w300${detail.poster_path}')`, zIndex: `10000` }}></div>
                                    <div className="no-video-text-container">
                                        <h2 className="no-video-text">Pas de bande-annonce disponible</h2>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row align-items-start">
                            <div className="col-md-3 d-flex flex-column align-items-start">
                                <div id="dateTime">
                                    <p>
                                        Année de sortie: <span id="spanInfoMovie">{detail.release_date?.substring(0, 4)}</span>
                                        <span> | </span> Durée: <span id="spanInfoMovie">{detail.runtime} minutes</span>
                                        {detail.production_countries && detail.production_countries.length > 0 && (
                                            <>
                                                <span> | </span> Origine:
                                                {detail.production_countries.map(country => (
                                                    <Flag code={country.iso_3166_1} key={country.iso_3166_1} className="flag-icon" />
                                                ))}
                                            </>
                                        )}
                                    </p>
                                    {isLoggedIn && (
                                        <div className="favoriteMovie" onClick={addFavoritePreview}>
                                            <img src={`${process.env.PUBLIC_URL}/images/favoriteMovie01.png`} alt="coeur" className="coeurMovie" />
                                        </div>
                                    )}
                                    {/* 2. Afficher le message selon l'état */}
                                    {showConfirmation && <h2 style={{ color: "#00FFFF", margin: "8vh 0 0 5vw" }}>Film ajouté à vos favoris !</h2>}
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <h1 className="movie-titleFiche">{detail.title}</h1>
                                <div className="movie-genresStyle">
                                    {genres.map((genre, index) => (
                                        <Link
                                            key={genre.id}
                                            to={{
                                                pathname: `/categories/${genre.id}`,
                                                state: {
                                                    id: genre.id,
                                                    name: genre.name,
                                                },
                                            }}
                                            className="text-genre"
                                            style={{ marginRight: "10px" }}
                                        >
                                            {genre.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-3 d-flex flex-column align-items-start">
                                <div className="sectChart">
                                    <h2 className="text-uppercase mb-3 noteCinephile">NOTES DES<br />CINÉPHILES</h2>
                                    <div className="chart-container chart-wrapper">
                                        <Doughnut data={doughnut} options={chartOptions} />
                                        <div className="chart-text">{publicPercent}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row movie-overview-container">
                            <div className="col-12 col-md-4 movie-overview mobile-movie-overview">
                                <p>{detail.overview}</p>
                            </div>
                            <div className="col-md-4 d-flex afficheInfo">
                                <div className="col-lg-6">
                                    <img
                                        src={`https://image.tmdb.org/t/p/w300${detail.poster_path}`}
                                        alt={detail.title}
                                        className="img-fluid imgFluidAffiche"
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <div className="general-info-section">
                                        <div className="general-info-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                        <p className="info-text">Généralités de <span className="info-highlight">{detail.title}</span></p>
                                        <p className="info-text">Réalisateur : <span className="info-highlight">{detail.director}</span></p>
                                        <p className="info-text">Titre Original : <span className="info-highlight">{detail.original_title}</span></p>
                                        <p className="info-text">Scénariste : <span className="info-highlight">{detail.writer}</span></p>
                                        <p className="info-text">Classe de maturité : <span className="info-highlight">{detail.age_rating} ans.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Container className="mt-5 actors-container">
                                    <div className="movie-actors">
                                        <h5>Acteurs:</h5>
                                        <div className="row">
                                            {actors && actors.slice(0, 4).map((actor, index) => (
                                                <div key={index} className="col-lg-3">
                                                    <Link to={{ pathname: `/acteurDetails/${actor.id}`, state: { id: actor.id } }} className="linkActorFicheFilm">
                                                        <img
                                                            src={actor.profile_path ? `https://image.tmdb.org/t/p/w500${actor.profile_path}` : 'https://zupimages.net/up/23/14/zdoe.jpg'}
                                                            alt="actor profile"
                                                            className={`substitute-image${index === 3 ? ' mainActors-image4' : ''}`}
                                                        />
                                                        <div className="actor-name-container">
                                                            <li className="actor-name" id="actor-nameId">{actor.original_name}</li>
                                                            <li className="actor-name actor-character">{actor.character}</li>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                        {actors && actors.length > 4 && (
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="secondary-roles">
                                                        <h5>Rôles secondaires:</h5>
                                                        <p>
                                                            {actors.slice(4).map((actor, index) => (
                                                                <span key={index}>{actor.original_name}{index < actors.slice(4).length - 1 && ", "}</span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </ActeurContext.Provider>
            <div className="actor-details-footer"
                style={{ maxWidth: '100%', overflowX: 'hidden', maxHeight: '100%', overflowY: 'hidden' }}
            >
                <Footer />
            </div>

        </React.Fragment>

    );
};

export default FicheFilm;




