export const validateTest = (user, isSignup = true) => {
    const errors = {};

    if (isSignup && !user.username.trim()) {
        errors.username = "Username is required";
    }

    if (isSignup && (!user.firstName.trim() || !user.lastName.trim())) {
        errors.name = "Both first and last name are required";
    }

    if (!user.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
        errors.email = "Email address is invalid";
    }

    if (!user.password) {
        errors.password = "Password is required";
    } else if (user.password.length < 8) {
        errors.password = "Password needs to be 8 characters or more";
    } else if (!/[a-z]/.test(user.password) || 
               !/[A-Z]/.test(user.password) || 
               !/[0-9]/.test(user.password) || 
               !/[\$\-\@\&]/.test(user.password)) {
        errors.password = "Password needs to contain at least one lowercase letter, one uppercase letter, one number, and one special character ($, -, @, &)";
    }    

    if (isSignup && !user.confirmPassword) {
        errors.confirmPassword = "Please confirm your password";
    } else if (isSignup && user.password !== user.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
    }

    return errors;
};
